export const randStr = (length = 4) =>
  Math.random().toString(36).substring(2, length + 2)

export const toCamelCase = str =>
  str
    .replace(/^\w|\b\w/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '')

export const fromCamelCase = str =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^\w|\b\w/g, (letter, index) =>
      index === 0 ? letter.toUpperCase() : letter.toLowerCase()
    )

export const isStringEqual = standard => value => {
  return standard === value
}

/**
 * Combina due o più funzioni usando logica OR.
 * @param  {...Array<(input: T) => boolean>} funcs - Le funzioni da combinare.
 * @returns {(input: T) => boolean} - Una funzione composta che restituisce true se una delle funzioni è true.
 */
export function or (...funcs) {
  return input => funcs.some(func => func(input))
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useHttp from '../../../hooks/useHttp'
import { STATE_MACHINE, useMergeContext } from '../../../store/mergeContext'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import {
  getColumnsModel,
  getConfigOptions,
  mapObjectToLabels
} from '../../Statistics/utils'
import { CRASH_ID_KEY } from '../utils'
import { isStringEqual, or } from '../../../utils'
import { Box, Stack } from '@mui/system'
import { Button, ButtonGroup, Typography } from '@mui/material'
import styled from '@emotion/styled'

const UNIT_ID_KEY = 'Unit id'
const CRASH_DATE_KEY = 'Crash date'
const CRASH_TYPE_KEY = 'Crash type'
const TYPE_KEY = 'Type'
const VEICHLE_REGISTRATION_KEY = 'Vehicle registration year'
const GENDER_KEY = 'Gender'
const NAME_KEY = 'Name'
const SURNAME_KEY = 'Surname'

const KEYS_TO_KEEP_IN_COLUMNS = [
  UNIT_ID_KEY
  , CRASH_ID_KEY
  , UNIT_ID_KEY
  , CRASH_DATE_KEY
  , CRASH_TYPE_KEY
  , TYPE_KEY
  , VEICHLE_REGISTRATION_KEY
  , GENDER_KEY
  , NAME_KEY
  , SURNAME_KEY
]

const keysToFunctions = KEYS_TO_KEEP_IN_COLUMNS.map(isStringEqual)

const keysToKeepFilterFunction = or(...keysToFunctions)

export const MatchTable = () => {
  const {
    selectedCrash,
    matches,
    setMatches,
    setCurrentState,
    setSelectedMatchDetail,
    setSelectedCrashDetail
  } = useMergeContext()
  const myLocation = useLocation()
  const token = myLocation.state?.token || null
  const { sendRequest } = useHttp()
  const [crashDetail, setCrashDetail] = useState([{}])
  const [columnGroupingModel, setColumnGroupingModel] = useState([])
  const [selectedCrashRows, setSelectedCrashRows] = useState([])
  const init = useMemo(
    () => myLocation.state?.init || {},
    [myLocation.state?.init]
  )
  const [columns, setColumns] = useState([])
  const [matchesRows, setMatchesRows] = useState([])

  const [selectedMatch, setSelectedMatch] = useState(false)

  useEffect(() => {
    const myMatch = matches?.find(
      m => m?.crash?.[CRASH_ID_KEY] === selectedMatch
    )
    console.log('my match::', myMatch, matches)
    setSelectedMatchDetail(myMatch)
  }, [selectedMatch, matches, setSelectedMatchDetail])

  const DISABLE_VALIDATE_CRASH = useMemo(() => matches?.length, [matches])
  const DISABLE_MERGE_CRASHES = useMemo(() => !selectedMatch, [selectedMatch])

  const repeatedCrash = useMemo(() => {
    return crashDetail || []
  }, [crashDetail])
  console.log('repeated crash', repeatedCrash)

  console.log('crash detail::', crashDetail)
  console.log('repeated crash::', repeatedCrash)

  // eslint-disable-next-line no-restricted-globals

  const getMatches = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    const matchUrl = new URL(`/api/matches/${selectedCrash}`, location)
    const matchUrlReduced = new URL(matchUrl)
    await sendRequest({
      token
      , url           : matchUrlReduced
      , manageResData : res => setMatches(res?.matches)
    })
    // eslint-disable-next-line no-restricted-globals
  }, [token, sendRequest, selectedCrash, setMatches])

  const getCrashes = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    const crashUrl = new URL(`/api/crash/${selectedCrash}`, location)
    const crashUrlReduced = new URL(crashUrl)
    crashUrlReduced.searchParams.set('format', 'table')
    await sendRequest({
      token
      , url           : crashUrlReduced
      , manageResData : res => (
        console.log('res crash', res), setCrashDetail(res?.crashes)
      )
    })
    // eslint-disable-next-line no-restricted-globals
  }, [token, sendRequest, selectedCrash])

  useEffect(() => {
    if (selectedCrash) {
      getCrashes()
    }
  }, [selectedCrash, getCrashes])

  useEffect(() => {
    if (selectedCrash) {
      getMatches()
    }
  }, [selectedCrash, getMatches])

  useEffect(() => {
    const { configuration } = init

    const configOptions = getConfigOptions(configuration)
    const mappedMatches = matches?.map(match =>
      mapObjectToLabels(match, configOptions)
    )

    const preparedRows = mappedMatches.map((crash, i) => ({
      ...(crash?.road || [])
      , ...(crash?.unit || [])
      , ...(crash?.unit?.people || [])
      , ...(crash?.people?.flatMap || [])
      , ...(crash?.crash || [])
      , ...(crash || [])
      , id: `${crash?.crash?.[CRASH_ID_KEY]}.${i}`
    }))

    console.group('matches::')
    console.log('mapped matches::', mappedMatches)
    console.log('prepared matches::', preparedRows)
    console.groupEnd()
    setMatchesRows(preparedRows)
  }, [matches, init])

  useEffect(() => {
    const { configuration } = init
    // setUser(user)
    // setTotalCrashes(totalCrashes)

    if (repeatedCrash?.length) {
      const configOptions = getConfigOptions(configuration)

      const mappedCrashes = repeatedCrash?.map(crash =>
        mapObjectToLabels(crash, configOptions)
      )
      console.log('mapped crash::', mappedCrashes)

      const preparedRows = mappedCrashes.map((crash, i) => (console.log('crash::', crash), {
        ...(crash?.road || [])
        , ...(crash?.unit || [])
        , ...(crash?.unit?.people || [])
        , ...(crash?.people || [])
        , ...(crash?.crash || [])
        , ...(crash || [])
        , id: `${crash?.crash?.[CRASH_ID_KEY]}.${i}`
      }))

      console.log('prepared rows', preparedRows)

      setSelectedCrashRows(preparedRows)

      const { columns, columnGroupingModel } = getColumnsModel(configuration)
      console.log('columns', columns)
      setColumns(
        columns
          ?.filter(col => keysToKeepFilterFunction(col?.field))
          ?.map(col => ({ ...col, flex: 1 }))
      )
      setColumnGroupingModel(columnGroupingModel)
    }
    // console.log('GM', columnGroupingModel, 'COLS', columns)
  }, [init, repeatedCrash])

  const paginationModel = { page: 0, pageSize: 25 }

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    border                           : 0
    , [`& .${gridClasses.row}.active`] : {
      backgroundColor: theme.palette.grey[200]
    }
    , '& .MuiDataGrid-menuIcon > *': {
      color: theme.palette.primary.contrastText
    }
    , '& .MuiDataGrid-iconButtonContainer > *': {
      color: theme.palette.primary.contrastText
    }
  }))

  const rowClassNameGetterCrash = params => {
    if (params?.row?.[CRASH_ID_KEY] === selectedCrash) {
      return 'active'
    }
    return ''
  }

  return (
    <Stack gap={6}>
      <Box>
        <Typography sx={{ width: '100%', mt: 2 }} variant="h5">
          Selected crash
        </Typography>
        <StripedDataGrid
          showColumnVerticalBorder
          showCellVerticalBorder
          unstable_rowSpanning
          rows={selectedCrashRows}
          columns={columns}
          editMode="row"
          columnGroupingModel={columnGroupingModel}
          disableMultipleRowSelection
          getRowClassName={rowClassNameGetterCrash}
          // disableRowSelectionOnClick
          initialState={{
            pagination : { paginationModel }
            , columns    : { columnVisibilityModel: { id: false } }
          }}
          pageSizeOptions={[10, 25, 50]}
        />
      </Box>

      <Box>
        <Typography sx={{ width: '100%', mt: 2 }} variant="h5">
          Possible matches
        </Typography>
        <DataGrid
          showColumnVerticalBorder
          showCellVerticalBorder
          unstable_rowSpanning
          rows={matchesRows}
          columns={columns}
          editMode="row"
          columnGroupingModel={columnGroupingModel}
          onRowClick={e => setSelectedMatch(e?.row?.[CRASH_ID_KEY])}
          disableMultipleRowSelection
          rowSelection
          // rowSelectionModel=''
          // disableRowSelectionOnClick
          initialState={{
            pagination : { paginationModel }
            , columns    : { columnVisibilityModel: { id: false } }
          }}
          pageSizeOptions={[10, 25, 50]}
        />
      </Box>
      <Box alignSelf="end" paddingY={3}>
        <ButtonGroup variant="contained">
          <Button
            onClick={() => {
              setCurrentState(STATE_MACHINE.MERGE_PAGE)
            }}
            disabled={DISABLE_MERGE_CRASHES}
          >
            Merge selected crashes
          </Button>
          <Button
            onClick={() => {
              setCurrentState(STATE_MACHINE.MERGE_PAGE)
            }}
            disabled={DISABLE_VALIDATE_CRASH}
            variant="outlined"
          >
            Validate crash
          </Button>
        </ButtonGroup>
      </Box>
    </Stack>
  )
}

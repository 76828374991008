export const mapObjectToLabels = (object, config) => {
  const getLabel = (configField, value) => {
    const item = config[configField]?.find(i => i.value === value)
    return item ? item.label : value // Return value if label not found
  }

  const mappedobject = {}
  // console.log('object::', object)

  Object.keys(object || {}).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key]
      // console.log('mapped object inside foreach', mappedobject)

      if (typeof value === 'object' && value !== null && Object.keys(value).length) {
        // Recursively map nested objects
        mappedobject[key] = mapObjectToLabels(value, config)
      } else {
        // Attempt to map the current key to a label if it exists in conf
        mappedobject[key] = getLabel(key, value)
      }
    }
  })
  // console.log(' returned mapped object::', mappedobject)

  return mappedobject
}

export const getConfigOptions = (configuration, organizations) => {
  let configOptions = {}

  Object.keys(configuration)
    .sort((a, b) => configuration[a].position - configuration[b].position)
    .forEach(section => {
      configuration[section].elements.forEach(elem => {
        if (elem.options.length) configOptions = { ...configOptions, [elem.label]: elem.options }
      })
    })

  return {
    ...configOptions
    , organization: organizations?.filter(org => org.value > 0)?.map(org => ({ value: org.value, label: org.label }))
  }
}

export const getColumnsModel = configuration => {
  let columns = [{
    field                : 'organization'
    , headerName         : 'Collected by'
    , headerClassName    : 'overload-theme--header'
    , valueGetter        : (value, row) => row.merge_date ? 'Merged' : value
    , rowSpanValueGetter : (value, row) => {
      const currentCrashId = row['Crash id']
      return `${value}-${currentCrashId}`
    }
  }]
  let columnGroupingModel = []

  Object.keys(configuration)
    .sort((a, b) => configuration[a].position - configuration[b].position)
    .forEach(section => {
      let children = []
      configuration[section].elements.forEach(elem => {
        const defaultColumn = {
          field                : elem.label
          , headerName         : elem.label
          , headerClassName    : 'overload-theme--header'
        }

        if (section === 'people') {
          columns = [
            ...columns
            , { ...defaultColumn, rowSpanValueGetter: () => null }
          ]
        } else if (section === 'units') {
          columns = [
            ...columns
            , {
              ...defaultColumn
              , rowSpanValueGetter: (value, row) => {
                const currentUnitId = row?.unit?.['Unit id']
                return `${value}-${currentUnitId}`
              }
            }
          ]
        } else {
          let minWidth

          switch (true) {
            case elem.label === 'Report date':
              minWidth = 100
              break
            case elem.label === 'Crash date':
              minWidth = 160
              break
            case elem.label === 'Crash id':
              minWidth = 140
              break
            default:
              break
          }

          columns = [
            ...columns
            , {
              ...defaultColumn
              , minWidth
              , rowSpanValueGetter: (value, row) => {
                const currentCrashId = row['Crash id']
                return `${value}-${currentCrashId}`
              }
            }
          ]
        }

        children = [...children, { field: elem.label }]
      })
      columnGroupingModel = [...columnGroupingModel, { groupId: section, headerClassName: 'overload-theme--header', children }]
    })

  const columnGroupingModelWOrg = columnGroupingModel
    .map(group => group.groupId === 'crash' ? ({ ...group, children: [...group.children, { field: 'organization' }] }) : group)

  return { columns, columnGroupingModelWOrg }
}

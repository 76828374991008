import { useEffect, useState } from 'react'
import { useLingui } from '@lingui/react'
import { msg, Trans } from '@lingui/macro'
import { Box, Button, styled, useTheme } from '@mui/material/'
import {
  GridRowModes
  , DataGrid
  , GridToolbarContainer
  , GridActionsCellItem
  , GridRowEditStopReasons
  , gridClasses
} from '@mui/x-data-grid'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { randStr } from '../../utils'

/*
basic_id: 1
description : "Police"
id: 1
short_description: "PL"
 */

const paginationModel = { page: 0, pageSize: 25 }

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  border                         : 0
  , [`& .${gridClasses.row}.even`] : {
    backgroundColor: theme.palette.grey[200]
  }
  , '& .MuiDataGrid-menuIcon > *': {
    color: theme.palette.primary.contrastText
  }
  , '& .MuiDataGrid-iconButtonContainer > *': {
    color: theme.palette.primary.contrastText
  }
}))

function EditToolbar ({ setRows, setRowModesModel }) {
  const handleClick = () => {
    const id = randStr()
    setRows(oldConfig => [
      {
        id
        , description       : ''
        , short_description : ''
        , basic_id          : -1
        , isNew             : true
      }
      , ...oldConfig
    ])
    setRowModesModel(oldModel => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'description' }
      , ...oldModel
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddCircleIcon />} onClick={handleClick}>
        <Trans>Add new Organization</Trans>
      </Button>
    </GridToolbarContainer>
  )
}

export default function OrganizationsTable ({ organizations, addOrModifyOrganization, deleteOrganization }) {
  const theme = useTheme()
  const { _ } = useLingui()
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})

  useEffect(() => {
    if (organizations.length) setRows(organizations.map(org => ({ ...org, value: org.id })))
  }, [organizations])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleDeleteClick = id => () => {
    deleteOrganization(id)
    setRows(rows.filter(row => row.id !== id))
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel
      , [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    addOrModifyOrganization(newRow)
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    {
      field             : 'id'
      , headerName      : _(msg`ID`)
      , headerClassName : 'overload-header'
      , headerAlign     : 'center'
      , align           : 'center'
      , flex            : 0.3 // , minWidth        : 30
      , valueFormatter  : value => Number.isInteger(value) ? value : _(msg`TBD`)
    }
    , {
      field             : 'description'
      , headerName      : _(msg`Official name`)
      , headerClassName : 'overload-header'
      , editable        : true
      , flex            : 1.5 // , minWidth        : 150
    }
    , {
      field             : 'short_description'
      , headerName      : _(msg`Short name`)
      , headerClassName : 'overload-header'
      , editable        : true
      , flex            : 0.7 // , minWidth        : 70
    }
    , {
      field             : 'basic_id'
      , headerName      : _(msg`Input form type`)
      , headerClassName : 'overload-header'
      , editable        : true
      , flex            : 1 // , minWidth        : 100
      , valueGetter     : value => value === -1 ? 'Not authorized' : organizations.find(o => o.basic_id === value)?.description
      , valueSetter     : (value, row) => {
        if (value) {
          const regex = /\b(police|healthcare|insurance)\b/gi
          const match = regex.exec(value)
          const newValue = match && match.length ? organizations.find(o => o.description === match[0]).basic_id : -1
          return { ...row, basic_id: newValue }
        }
      }
    }
    , {
      field             : 'actions'
      , type            : 'actions'
      , headerName      : _(msg`Actions`)
      , headerClassName : 'overload-header'
      , cellClassName   : 'actions'
      , flex            : 0.7 // , minWidth        : 70
      , getActions      : ({ id }) => {
        const isInEditMode = () => {
          return rowModesModel[id]?.mode === GridRowModes.Edit
        }

        if (isInEditMode()) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label={_(msg`Save`)}
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />
            , <GridActionsCellItem
              icon={<CancelIcon />}
              label={_(msg`Cancel`)}
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />
          ]
        }

        if (id > 3) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label={_(msg`Edit`)}
              className='textPrimary'
              onClick={handleEditClick(id)}
              color='inherit'
            />
            , < GridActionsCellItem
              icon={<DeleteIcon />}
              label={_(msg`Delete`)}
              onClick={handleDeleteClick(id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={_(msg`Edit`)}
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  return (
    <Box
      width='100%'
      sx={{
        '& .overload-header': {
          backgroundColor   : theme.palette.primary.main
          , color           : theme.palette.primary.contrastText
        }
      }}
    >
      <StripedDataGrid
        showColumnVerticalBorder
        showCellVerticalBorder
        rows={rows}
        columns={columns}
        editMode='row'
        rowModesModel={rowModesModel}
        onRowModesModelChange={() => handleRowModesModelChange('crash')}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination   : { paginationModel }
          , columns    : {
            columnVisibilityModel: { isNew: false }
          }
        }}
        hideFooter={rows.length <= paginationModel.pageSize}
        pageSizeOptions={[paginationModel.pageSize]}
        getRowClassName={params => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
      />
    </Box>
  )
}

import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import {
  Box
  , Container
  , Link
  , Typography
} from '@mui/material/'
import Copyright from '../components/Copyright'
import UserForm from '../components/forms/UserForm'

const flag = `url('assets/${process.env.REACT_APP_FLAG}')`

export default function Signup ({ isMobile }) {
  const navigate = useNavigate()
  const location = useLocation()
  const token = location.state?.token || null

  useEffect(() => {
    if (token) return navigate('/home', { state: { token } })
  }, [token, navigate])

  return (
    <Container component="main" maxWidth={isMobile ? 'xs' : 'sm'}>
      <Box
        mt={8}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          width='50%'
          mb='1rem'
          border='1px solid #c4c4c4'
          borderRadius={1}
          sx={{
            aspectRatio       : '3/2'
            , backgroundImage : flag
            , backgroundSize  : 'cover'
          }}>
        </Box>
        <Box width='50%'>
          <img src='../assets/logo_adams.png' alt='ADaMS logo' width='100%' />
        </Box>
        <UserForm isMobile={isMobile} token={token} />
        <Typography variant='body2'>
          <Trans>Already signed up? Please&nbsp;
            <Link href="/" variant='inherit'>login</Link>
          </Trans>
        </Typography>
      </Box>
      <Box mt={3} mb={2} display='flex' justifyContent='space-evenly' gap={2}>
        {/* <Box display='flex' alignItems='center' justifyContent='center' bgcolor='#4a4a4a' borderRadius={5} width={100} height={100} sx={{ transform: 'rotate(45deg)' }}> */}
        <Box display='flex' alignItems='center' justifyContent='center' width='50%'>
          <img src='../assets/logo_fred.png' alt='Fred logo' width='90%' />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' width='50%'>
          <img src='#' alt="Sponsor" width='90%' height='90%' style={{ border: '1px solid #c4c4c4' }}/>
        </Box>
      </Box>
      <Box mt={4} display='flex' justifyContent='center'>
        <Copyright />
      </Box>
    </Container>
  )
}

import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { AppBar, Box, IconButton, Toolbar, Typography, useTheme } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import useLogout from '../../hooks/useLogout'
import OrganizationIcon from '../OrganizationIcon'

export default function MobileTopNav () {
  const theme = useTheme()
  const location = useLocation()
  const { state, state: { token, init } } = location
  const navigate = useNavigate()
  const { _ } = useLingui()

  const logout = useLogout()

  useEffect(() => {
    if (!token) logout()
  }, [logout, token])

  return (
    <AppBar sx={{
      boxShadow         : 1
      , backgroundColor : theme.palette.background.default
    }}>
      <Toolbar>
        <img src='../assets/logo_adams.png' alt='ADaMS logo' style={{ width: '25%', maxWidth: 150 }} />
        <Typography component="div" sx={{ flexGrow: 1 }}></Typography>
        <Box color={theme.palette.text.primary}>
          {Object.keys(init).length &&
            <>
              <Typography variant="button">{init.user.username}</Typography>
              <IconButton
                edge='end'
                size="large"
                aria-label={_(msg`account of current user`)}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => navigate('/user', { state })}
              >
                <OrganizationIcon isMobile={true} organization={init.user.organization} />
              </IconButton>
            </>}
          <IconButton
            edge='end'
            size="large"
            aria-label={_(msg`logout`)}
            aria-controls="menu-appbar"
            aria-haspopup="false"
            onClick={() => logout()}
          >
            <LogoutIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

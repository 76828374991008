import { useCallback, useEffect, useState } from 'react'
import {
  Box
  , Button
  , Divider
  , FormControl
  , IconButton
  , ImageList
  , ImageListItem
  , ImageListItemBar
  , InputAdornment
  , InputLabel
  , ListSubheader
  , MenuItem
  , Select
  , Stack
  , SwipeableDrawer
  , TextField
  , Tooltip
  , Typography
} from '@mui/material'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import ImageIcon from '@mui/icons-material/Image'
import CollectionsIcon from '@mui/icons-material/Collections'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DeleteIcon from '@mui/icons-material/Delete'
import { tooltipClasses } from '@mui/material/Tooltip'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker, DateTimePicker, renderTimeViewClock, DatePicker, MobileDatePicker } from '@mui/x-date-pickers/'
import { useFormContext, Controller } from 'react-hook-form'
// import moment from 'moment'
import dayjs from 'dayjs'
import CameraBox from '../Camera'
import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ErrorBoundary from '../ErrorBoundary'

const DynamicForm = ({ isMobile, formModel, form, formList }) => {
  const { control } = useFormContext()

  return (
    <>
      {formModel[form.type].elements
        .filter(elem => (elem.type !== 'coordinates' &&
          elem.label !== 'Crash id' &&
          elem.label !== 'Report date' &&
          elem.label !== 'Crash date'
        ))
        .map(elem => {
          if (elem.type === 'datetime') {
            return (
              <FormControl key={`${form.type}-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={`${formList[form.number]}.${elem.label}`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      isMobile
                        ? <MobileDateTimePicker
                          label={elem.label}
                          disableFuture
                          value={value ? dayjs(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                        : <DateTimePicker
                          label={elem.label}
                          disableFuture
                          value={value ? dayjs(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          viewRenderers={{
                            hours   : renderTimeViewClock
                            , minutes : renderTimeViewClock
                            , seconds : renderTimeViewClock
                          }}
                          sx={{ marginTop: 1 }}
                        />
                    )
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            )
          }

          if (elem.type === 'date') {
            return (
              <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={`${formList[form.number]}.${elem.label}`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      isMobile
                        ? <MobileDatePicker
                          label={elem.label}
                          disableFuture
                          defaultValue={value ? dayjs(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                        : <DatePicker
                          label={elem.label}
                          disableFuture
                          defaultValue={value ? dayjs(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            )
          }

          /* it could need to be unregistered */
          // if (elem.type === 'divider') {
          //   return (<Divider key={`${form.type}-${elem.type}`} aria-hidden='true' sx={{ margin: '1rem 0' }} />)
          // }

          if (elem.type === 'select') {
            return (
            <FormControl key={`${elem.type}-${elem.label}`} margin='normal'>
              <InputLabel id={`select-${elem.label}`}>{elem.label}</InputLabel>
              <Controller
                name={`${formList[form.number]}.${elem.label}`}
                control={control}
                render={({ field }) => (
                  // <Tooltip
                  //   placement='right'
                  //   title='ALL TOOLLTIIIPPPPPSSSSS'
                  //   slotProps={{
                  //     popper: {
                  //       sx: {
                  //         [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]:
                  //           {
                  //             marginLeft: 4
                  //           }
                  //       }
                  //     }
                  //   }}
                  // >
                    <Select
                      {...field}
                      labelId={`select-${elem.label}`}
                      id={`select-${elem.label}`}
                      label={elem.label}
                      value={field.value}
                      onChange={field.onChange}
                      startAdornment={elem.prefix && <InputAdornment>{elem.prefix}&nbsp;</InputAdornment>}
                    >
                      {elem.options.map(opt => {
                        if (field.name.includes('Nationality') || field.name.includes('country')) {
                          return (
                            <MenuItem key={opt.label} value={opt.value}>
                              <Box display='flex'>
                                {opt.value !== 0 && <Box
                                  height='1.5rem'
                                  border='1px solid #c4c4c4'
                                  borderRadius={1}
                                  sx={{
                                    aspectRatio        : '4/3'
                                    , backgroundImage    : `url('${opt.flag}')`
                                    , backgroundSize     : 'cover'
                                    , backgroundPosition : 'center center'
                                  }}
                                />}
                                <Typography ml={opt.value !== 0 ? 3 : 0}>{opt.label}</Typography>
                              </Box>
                            </MenuItem>
                          )
                        } else {
                          return opt.value && opt.value % 100 === 0
                            ? <ListSubheader key={`${opt.label}-sub`}>{opt.label}</ListSubheader>
                            : <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>
                        }
                      })}
                    </Select>
                  // </Tooltip>
                )}
              />
            </FormControl>
            )
          }

          return (
          <FormControl key={`${elem.type}-${elem.label}`}>
            <Controller
              name={`${formList[form.number]}.${elem.label}`}
              control={control}
              render={({ field }) => (
                <TextField
                  margin={elem.margin || 'normal'}
                  fullWidth
                  required={elem.isRequired}
                  id={elem.label}
                  label={elem.label}
                  value={field.value}
                  onChange={field.onChange}
                  {...field}
                />
              )}
            />
          </FormControl>
          )
        })}
    </>
  )
}

const Form = ({
  token
  , isMobile
  , submitRef
  , form
  , formModel
  , formList
  , formArray
  , handleCrashSubmit
  , isFilterForm
  , images
}) => {
  const { control, setValue, handleSubmit, watch, handlePicture } = useFormContext()
  const watchReportDate = watch('crash.Report date')
  const watchCrashDate = watch('crash.Crash date')
  const { _ } = useLingui()
  const [isCameraOpen, setIsCameraOpen] = useState(false)

  console.log(formArray, form.type)

  const getNewId = useCallback(date => {
    // eslint-disable-next-line no-restricted-globals
    const newCrashIdUrl = new URL('/api/crash/new_crash_id', location)
    newCrashIdUrl.searchParams.set('date', dayjs(date).format('YYYY-MM-DD'))

    fetch(newCrashIdUrl, {
      headers: {
        Authorization  : `Bearer ${token}`
        , 'Content-Type' : 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        setValue('crash.Crash id', res.crash_id) // Set the value of Crash id
      })
  }, [token, setValue])

  useEffect(() => {
    if (!isFilterForm && form.type === 'crash') {
      if (!watchReportDate) setValue('crash.Report date', dayjs())
      if (!watchCrashDate) setValue('crash.Crash date', dayjs())
      getNewId(watchCrashDate)
    }
  }, [watchReportDate, watchCrashDate, isFilterForm, form.type, setValue, getNewId])

  // Handle file input change
  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      // const imageUrl = URL.createObjectURL(file)
      const filereader = new FileReader()
      filereader.readAsDataURL(file)
      filereader.onload = evt => {
        const base64 = evt.target.result
        handlePicture(base64)
      }
    }
  }

  const handleCameraClose = () => { setIsCameraOpen(false) }

  return (
    <Box
      name={form.type}
      component="form"
      ref={submitRef}
      onSubmit={handleSubmit(handleCrashSubmit)}
      display='flex'
      flexDirection='column'
      pl={1}
      pr={1}
      noValidate
    >
      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Report date')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name={'crash.Report date'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    isMobile
                      ? <MobileDatePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? dayjs(value)
                          : isFilterForm
                            ? null
                            : dayjs()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                      : <DatePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? dayjs(value)
                          : isFilterForm
                            ? null
                            : dayjs()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
        ))}

      {form.type === 'crash' && <Divider key='crash-divider' aria-hidden='true' sx={{ margin: '1rem 0' }} />}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Crash id')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`}>
              <Controller
                name={'crash.Crash id'}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    margin={elem.margin || 'normal'}
                    required={elem.isRequired}
                    id={elem.label}
                    label={elem.label}
                    value={field.value}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  />
                )}
              />
            </FormControl>
        ))}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Crash date')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name={`crash.${elem.label}`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    isMobile
                      ? <MobileDateTimePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? dayjs(value)
                          : isFilterForm
                            ? null
                            : dayjs()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                      : <DateTimePicker
                        label={elem.label}
                        disableFuture
                        value={value
                          ? dayjs(value)
                          : isFilterForm
                            ? null
                            : dayjs()
                        }
                        onAccept={date => {
                          onChange(date)
                        }}
                        viewRenderers={{
                          hours   : renderTimeViewClock
                          , minutes : renderTimeViewClock
                          , seconds : renderTimeViewClock
                        }}
                        sx={{ marginTop: 1 }}
                      />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
        ))}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.type === 'coordinates')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`}>
              <Controller
                name={`crash.${elem.label}`}
                control={control}
                render={({ field }) => (
                  <TextField
                    margin={elem.margin || 'normal'}
                    fullWidth
                    required={elem.isRequired}
                    id={elem.label}
                    label={elem.label}
                    value={field.value}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  />
                )}
              />
            </FormControl>
        ))}

      <DynamicForm
        isMobile={isMobile}
        formModel={formModel}
        formList={formList}
        form={form}
        formArray={formArray}
      />

      {form.type === 'crash' &&
        <Stack direction='row' spacing={1} justifyContent={isMobile ? 'space-between' : 'center'} sx={{ mt: 1.5 }}>
          {/* Button for Library Upload */}
              <Button
                variant='contained'
                component="label"
                size={isMobile ? 'medium' : 'large'}
                startIcon={<CollectionsIcon />}
              >
                <Trans>Upload Pictures</Trans>
                <input
                  type='file'
                  accept='image/*'
                  // capture='environment'
                  hidden
                  onChange={event => {
                    // handleFileChange(event)
                    const files = event.target.files
                    if (files.length) {
                      [...files].forEach(file => {
                        const filereader = new FileReader()
                        filereader.readAsDataURL(file)
                        // eslint-disable-next-line no-loop-func
                        filereader.onload = evt => {
                          const base64 = evt.target.result
                          handlePicture(base64)
                        }
                      })
                    }
                  }}
                />
              </Button>
          {isMobile &&
            <Button
              variant='contained'
              // component="label"
              size='medium'
              startIcon={<PhotoCamera />}
              onClick={() => {
                setIsCameraOpen(true)
              }}
            >
              <Trans>Take Picture</Trans>
            </Button>
          }
        </Stack>
      }
      {/* <SwipeableDrawer
        anchor='bottom'
        open={isCameraOpen}
        onClose={handleCameraClose}
        // onOpen={toggleDrawer(anchor, true)}
      >
        <CameraBox
          handlePicture={handlePicture}
          handleCameraClose={handleCameraClose}
        />
      </SwipeableDrawer> */}
      {isCameraOpen &&
        <ErrorBoundary>
          <CameraBox
            handlePicture={handlePicture}
            handleCameraClose={handleCameraClose}
          />
        </ErrorBoundary>
      }
      {/* Display the selected image */}
      {!!images?.length &&
        <ImageList sx={{ width: '100%' }}>
          {images.map((image, index) => (
            <ImageListItem key={index}>
              <img
                srcSet={image}
                src={image}
                alt={image}
                loading="lazy"
              />
              <ImageListItemBar
                title={index + 1}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={_(msg`delete picture no ${index}`)}
                    onClick={() => handlePicture(null, index)}
                  >
                    <DeleteIcon color='error' />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      }
    </Box>
  )
}

export default Form

// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import ReactWebcam from 'react-webcam';

// const CameraUpload = () => {
//   const { control, handleSubmit, reset } = useForm();
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [isWebcamOpen, setIsWebcamOpen] = useState(false);
//   const webcamRef = useRef(null);

//   // Handle file input change
//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const imageUrl = URL.createObjectURL(file);
//       setSelectedImage(imageUrl);
//     }
//   };

//   // Capture photo from webcam
//   const capturePhoto = () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setSelectedImage(imageSrc);
//     setIsWebcamOpen(false);
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <Stack direction="column" spacing={3} alignItems="center">
//         {/* Webcam Toggle Button */}
//         <Button
//           variant="contained"
//           onClick={() => setIsWebcamOpen(!isWebcamOpen)}
//           startIcon={<CameraAltIcon />}
//         >
//           {isWebcamOpen ? 'Close Camera' : 'Open Camera'}
//         </Button>

//         {/* Webcam Capture */}
//         {isWebcamOpen && (
//           <Box sx={{ marginBottom: 2 }}>
//             <ReactWebcam
//               audio={false}
//               ref={webcamRef}
//               screenshotFormat="image/jpeg"
//               width={300}
//               videoConstraints={{ facingMode: 'environment' }}
//             />
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={capturePhoto}
//               sx={{ marginTop: 2 }}
//             >
//               Capture Photo
//             </Button>
//           </Box>
//         )}

//         <Divider sx={{ width: '100%', marginY: 2 }} />

//         {/* File Upload Button */}
//         <Controller
//           name="image"
//           control={control}
//           render={({ field }) => (
//             <Button
//               variant="contained"
//               component="label"
//               startIcon={<PhotoCamera />}
//             >
//               Upload from Device
//               <input
//                 {...field}
//                 type="file"
//                 accept="image/*"
//                 hidden
//                 onChange={(event) => {
//                   handleFileChange(event);
//                   field.onChange(event.target.files);
//                 }}
//               />
//             </Button>
//           )}
//         />

//         {/* Optional Icon Button */}
//         <Controller
//           name="imageIconUpload"
//           control={control}
//           render={({ field }) => (
//             <IconButton
//               color="primary"
//               component="label"
//               aria-label="upload picture"
//             >
//               <UploadFileIcon />
//               <input
//                 {...field}
//                 type="file"
//                 accept="image/*"
//                 hidden
//                 onChange={(event) => {
//                   handleFileChange(event);
//                   field.onChange(event.target.files);
//                 }}
//               />
//             </IconButton>
//           )}
//         />

//         {/* Submit Button */}
//         <Button type="submit" variant="contained" color="success">
//           Submit
//         </Button>

//         {/* Reset Button */}
//         <Button
//           variant="outlined"
//           color="secondary"
//           onClick={() => {
//             reset();
//             setSelectedImage(null);
//           }}
//         >
//           Reset
//         </Button>

//         {/* Display the selected image */}
//         {selectedImage && (
//           <Box mt={3}>
//             <Typography variant="h6">Selected Image:</Typography>
//             <img
//               src={selectedImage}
//               alt="Selected"
//               style={{ width: '100%', maxWidth: '300px', borderRadius: '8px' }}
//             />
//           </Box>
//         )}
//       </Stack>
//     </form>
//   );
// };

// export default CameraUpload;

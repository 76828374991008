import { Routes, Route } from 'react-router-dom'
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery
} from '@mui/material'
import { RoutesProvider } from './store/routesContext'
import { DataMergingAndValidation } from './pages/DataMerginAndValidation'
import { MergeContext } from './store/mergeContext'
import Login from './pages/Login'
import Home from './pages/Home'
import Signup from './pages/Signup'
import User from './pages/User'
import Admin from './pages/Admin'
import Statistics from './pages/Statistics'
import History from './pages/History'
import Help from './pages/Help'
import { cloneElement } from 'react'
// import { orange } from '@mui/material/colors'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#10171f'
    }
  }
  , mixins: {
    drawerWidth       : 256
    , formDrawerWidth : 320
    , toolbar         : {
      minHeight: 64
    }
  }
  // , components: {
  //   MuiCssBaseline: {
  //     styleOverrides: themeParams => ({
  //       body: {
  //         scrollbarColor                                   : '#ebebeb #2b2b2b'
  //         , '&::-webkit-scrollbar, & *::-webkit-scrollbar' : {
  //           backgroundColor: '#ebebeb'
  //         }
  //         , '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
  //           width           : '8px'
  //           , backgroundColor : orange[500]// '#6b6b6b'
  //         }
  //         , '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
  //           borderRadius      : 8
  //           , backgroundColor : orange[500]// '#6b6b6b'
  //           , minHeight       : 24
  //           , border          : '0px solid #2b2b2b'
  //         }
  //         , '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
  //           backgroundColor: '#959595'
  //         }
  //         , '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
  //           backgroundColor: '#959595'
  //         }
  //         , '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
  //           backgroundColor: '#959595'
  //         }
  //         , '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
  //           backgroundColor: '#ff0000'
  //         }
  //       }
  //     })
  //   }
  // }
})

// {
//   '& .scroll-bar::-webkit-scrollbar-track'   : { width: '8px' }
//   , '& .scroll-bar::-webkit-scrollbar-track' : { background: '#d5d2d2' }
//   , '& .scroll-bar::-webkit-scrollbar-thumb' : { backgroundColor: '#7e7c7e',  borderRadius: '4px', border: '0px solid #7e7c7e' }
//   ,'& .scroll-bar::-webkit-scrollbar-thumb:hover': { backgroundColor: '#494849' }
// }

export default function App () {
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'))

  const routes = [
    { path: '/home', element: <Home /> }
    , { path: '/user', element: <User /> }
    , { path: '/statistics', element: <Statistics /> }
    , { path: '/admin', element: <Admin /> }
    , {
      path      : '/tools'
      , element : (
      <MergeContext>
        <DataMergingAndValidation />
      </MergeContext>
      )
    }
    , { path: '/history', element: <History /> }
    , { path: '/help', element: <Help /> }
  ]

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <RoutesProvider routes={routes.map(route => route.path)}>
        <Routes>
          <Route path="/" element={<Login isMobile={isMobile} />} />
          <Route path="/signup" element={<Signup isMobile={isMobile} />} />
          {routes.map(({ path, element }, position) =>
            <Route key={path} path={path} element={cloneElement(element, { position, isMobile })} />)}
        </Routes>
      </RoutesProvider>
    </ThemeProvider>
  )
}

import { registerInputs } from '../functions/registerInput'
import { Box, Divider, Grid, Button } from '@mui/material'
import { FormHeaderButton } from '../components/FormHeaderButton'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import { useMergeContext } from '../../../store/mergeContext'
import { CRASH_ID_KEY } from '../utils'
import { Fragment, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const DEFAULT_HEADERS = ['Value', 'Police', 'Hospital', 'Insurance', 'Merged']
const DEFAULT_GRID_SPACE = 2
const KEYS_TO_IGNORE = ['unitIndex', 'personIndex']

const prepareInputs = (values = {}, keysToIgnore = KEYS_TO_IGNORE) => {
  const result = {}

  const traverse = obj => {
    for (const key in obj) {
      if (key in obj && !keysToIgnore.includes(key)) {
        if (
          key === 'police' &&
          typeof obj[key] === 'object' &&
          obj[key] !== null
        ) {
          extractKeys(obj[key])
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          traverse(obj[key])
        }
      }
    }
  }

  const extractKeys = (obj, path = '') => {
    for (const key in obj) {
      if (key in obj && !keysToIgnore.includes(key)) {
        const newPath = path ? `${path}.${key}` : key
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          extractKeys(obj[key], newPath)
        } else {
          result[newPath] = obj[key]
        }
      }
    }
  }

  traverse(values)
  return result
}
const getMainKeyFromState = key => obj => {
  return obj?.[key]
}

const FUNCTIONS_TO_BUILD = ['police', 'hospital', 'insurance']

const checkIfValueExist = (keys, value) => {
  if (!!value && !!keys?.[0] && keys?.[0] in value) {
    if (!!value?.[keys?.[0]] && keys?.[1] in value?.[keys?.[0]]) {
      const valueToCheck = value?.[keys?.[0]]?.[keys?.[1]]
      return (
        valueToCheck !== null &&
        valueToCheck !== undefined &&
        valueToCheck !== ''
      )
    }
    return false
  }
  return false
}

const prepareRow = (
  inputs,
  values,
  registerInputs,
  register,
  control,
  watch,
  config
) => {
  const getOnlyField = name => {
    if (typeof name === 'string' && name?.includes('.')) {
      const { [name?.split('.')?.length - 1]: lastElem } = name?.split('.')
      return lastElem
    }
    return ''
  }

  const getOptions = name => {
    const field = getOnlyField(name)
    if (
      field &&
      field in config &&
      'options' in config?.[field] &&
      config?.[field]?.options?.length
    ) {
      return config?.[field]?.options?.map(opt => ({
        ...opt
        , value: opt?.value
      }))
    }

    if (watch(name) !== null && watch(name) !== undefined) {
      const value = watch(name)
      return { value: value?.toString(), label: value?.toString() }
    }
    return { value: '', label: '' }
  }
  return (
    <Fragment>
      {inputs?.map(input => {
        const valuesToCheck = input?.split('.')
        const myInputs = FUNCTIONS_TO_BUILD?.map(f =>
          checkIfValueExist(valuesToCheck, getMainKeyFromState(f)(values))
            ? (
                registerInputs({
                  inputs: [
                    {
                      registerName : `${f}.${valuesToCheck?.join('.')}`
                      , xs           : '2'
                      , options      : getOptions(input)
                      , type         : getOptions(input)?.length ? 'select' : ''
                      , disabled     : true
                    }
                  ]
                  , register
                  , control
                })
              )
            : (
            <Grid
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginY="1rem"
              xs="2"
            >
              No Column
            </Grid>
              )
        )

        const mapOptions = [
          ...FUNCTIONS_TO_BUILD?.flatMap(f => {
            const myOpt = getOptions(`${f}.${valuesToCheck?.join('.')}`)
            return myOpt
          })
          , { value: '', label: '' }
        ]

        const uniqueId = [
          ...new Set(
            mapOptions?.flatMap(opt =>
              Array.isArray(opt)
                ? opt?.flatMap(subOpt => subOpt)
                : opt?.value
            )
          )
        ]
        const uniqueOptions = uniqueId?.map(id =>
          mapOptions?.find(opt => opt?.value === id)
        )
        console.log('unique options::', uniqueOptions)

        const mergeInput = {
          registerName : `merged.${valuesToCheck?.join('.')}`
          , xs           : '2'
          , type         : uniqueOptions?.some(opt => opt?.value !== opt?.label)
            ? 'select'
            : 'autocomplete'
          , options: uniqueOptions
        }

        return (
          <Grid sx={{ borderBottom: '1px solid lightgray' }} container>
            <Grid
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginY="1rem"
              xs="2"
            >
              {valuesToCheck?.[1]}
            </Grid>
            {myInputs}
            {registerInputs({
              register
              , inputs: [mergeInput]
              , control
            })}
          </Grid>
        )
      })}
      <hr />
    </Fragment>
  )
}

export const MergeForm = () => {
  const { mergeState, updateSelectedCrashDetail } = useMergeContext()
  const { handleSubmit, register, watch, control } = useForm({
    values: { ...mergeState, merged: mergeState?.police }
  })

  const location = useLocation()
  const init = useMemo(
    () => location.state?.init || {},
    [location.state?.init]
  )
  const inputConfiguration = useMemo(() => init?.configuration, [init])
  const flatConfiguration = useMemo(
    () =>
      Object.values(inputConfiguration || {})?.flatMap(conf =>
        conf?.elements?.flatMap(ele => ele)
      ),
    [inputConfiguration]
  )
  const configurationMap = useMemo(
    () =>
      flatConfiguration?.reduce(
        (acc, val) => ({ ...acc, [val?.label]: { ...val } }),
        {}
      ),
    [flatConfiguration]
  )

  const onSubmit = values => {
    console.log('submit::', values)
    updateSelectedCrashDetail({
      type : values.type
      , data : { ...values.merged, done: true }
      , ...(values.index !== undefined && values.index !== null && { index: values.index })
    })
  }
  const inputs = useMemo(() => prepareInputs(mergeState), [mergeState])

  return (
    <Box>
      <Grid container>
        {DEFAULT_HEADERS?.map(heading => {
          return (
            <Grid xs={DEFAULT_GRID_SPACE} key={heading}>
              <FormHeaderButton>{heading}</FormHeaderButton>
            </Grid>
          )
        })}
      </Grid>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          {prepareRow(
            Object.keys(inputs || {}),
            watch(),
            registerInputs,
            register,
            control,
            watch,
            configurationMap
          )}
        </Grid>

          <Button sx={{ margin: '1rem' }} color='primary' variant='contained' type="submit">SUBMIT</Button>
      </form>
    </Box>
  )
}

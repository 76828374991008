import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { t } from '@lingui/macro'
import { Box, styled } from '@mui/material/'
import {
  GridRowModes
  , DataGrid
  , GridActionsCellItem
  , GridRowEditStopReasons
  , gridClasses
} from '@mui/x-data-grid'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'

// Editable fields by user
// name
// surname
// department
// password
// is_verified

// Example user
// id: 1
// username: ""
// name: ""
// surname: ""
// organization: 0
// department: ""
// phone: "00000000"
// email: ""
// type: 1
// create_date: "2024-09-06"
// is_verified: false

const paginationModel = { page: 0, pageSize: 25 }

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  border                           : 0
  , [`& .${gridClasses.row}.even`] : {
    backgroundColor: theme.palette.grey[200]
  }
  , '& .MuiDataGrid-menuIcon > *': {
    color: theme.palette.primary.contrastText
  }
  , '& .MuiDataGrid-iconButtonContainer > *': {
    color: theme.palette.primary.contrastText
  }
}))

export default function UsersTable ({ usersRows, modifyUser }) {
  const location = useLocation()
  const organizations = location.state?.init?.organizations || {}
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})

  useEffect(() => {
    if (usersRows.length) setRows(usersRows)
  }, [usersRows])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  // const handleDeleteClick = id => () => {
  //   setRows(rows.filter((row) => row.id !== id));
  // }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel
      , [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false }
    console.log('UPD', updatedRow, 'OLD', oldRow)
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    modifyUser(updatedRow)
    handleCancelClick(newRow.id)
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    {
      field             : 'id'
      , headerName      : t`ID`
      , headerClassName : 'overload-theme--header'
      , flex            : 0.5 // , minWidth: 50
    }
    , {
      field             : 'username'
      , headerName      : t`Username`
      , headerClassName : 'overload-theme--header'
      , flex            : 1.5 // , minWidth: 150
    }
    , {
      field             : 'name'
      , headerName      : t`First name`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'surname'
      , headerName      : t`Last name`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'organization'
      , headerName      : t`Organization`
      , headerClassName : 'overload-theme--header'
      , flex            : 1
      , valueGetter     : value => {
        if (value) {
          return organizations.find(o => o.value === value).label
        }
      }
    }
    , {
      field             : 'department'
      , headerName      : t`Department/Unit`
      , headerClassName : 'overload-theme--header'
      , editable        : true
      , flex            : 1.25 // , minWidth: 125
      , valueGetter     : (value, row) => row.organization === 1 ? value : null
    }
    , {
      field             : 'departmentAlt'
      , headerName      : t`Hospital/Healthcare facility`
      , headerClassName : 'overload-theme--header'
      , editable        : true
      , flex            : 1.25 // , minWidth: 125
      , valueGetter     : (_, row) => row.organization === 2 ? row.department : null
    }
    , {
      field             : 'phone'
      , headerName      : t`Phone`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'email'
      , headerName      : t`Email`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'create_date'
      , headerName      : t`Date created`
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'type'
      , headerName      : t`Role`
      , type            : 'singleSelect'
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
      , valueOptions    : [
        { label: t`User`, value: 0 }
        , { label: t`Admin`, value: 1 }
        , { label: t`Organization admin`, value: 2 }
      ]
    }
    , {
      field             : 'verified'
      , headerName      : t`Verified?`
      , type            : 'boolean'
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 0.75 // , minWidth: 75
    }
    , {
      field             : 'actions'
      , type            : 'actions'
      , headerName      : t`Actions`
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
      , cellClassName   : 'actions'
      , getActions      : ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label={t`Save`}
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />
            , <GridActionsCellItem
              icon={<CancelIcon />}
              label={t`Cancel`}
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t`Edit`}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ]
      }
    }
  ]

  return (
    <Box
      width='100%'
      sx={theme => ({
        '& .overload-theme--header': {
          backgroundColor   : theme.palette.primary.main
          , color           : theme.palette.primary.contrastText
        }
      })}
    >
      <StripedDataGrid
        showColumnVerticalBorder
        showCellVerticalBorder
        rows={rows}
        columns={columns}
        editMode="row"
        isCellEditable={params => params.row.type !== 1}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error => error}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[10, 25, 50]}
        getRowClassName={params => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }}
      />
    </Box>
  )
}

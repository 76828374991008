import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Trans } from '@lingui/macro'
import {
  Badge
  , Box
  , Button
  , Card
  , CardContent
  , CircularProgress
  , Dialog
  , DialogActions
  , DialogContent
  , DialogContentText
  , DialogTitle
  , Drawer
  , IconButton
  , styled
  , Typography
  , useTheme
} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import SaveIcon from '@mui/icons-material/Save'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined'
import PersonIcon from '@mui/icons-material/Person'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import EditRoadSharpIcon from '@mui/icons-material/EditRoadSharp'
import DeleteIcon from '@mui/icons-material/Delete'
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
// import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
// import InfoIcon from '@mui/icons-material/Info'
// import EditRoadIcon from '@mui/icons-material/EditRoad'
// import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined'
import Form from './Form'

const AddIcon = ({ variant }) => (
  <>
    {variant === 'person' && <PersonIcon />}
    {variant === 'unit' && <DirectionsCarIcon />}
    <Typography variant='h6' fontWeight={700}>+</Typography>
  </>
)

export default function FormContainer ({
  token
  , open
  , isMobile
  , isLoading
  , formList
  , formModel
  , form
  , submitRef
  , handleAppend
  , handleRemove
  , handleFormOpen
  , handleSetFormNumber
  , handleCrashSubmit
  , handlePositioningState
  , images
  , reporterData
}) {
  const location = useLocation()
  const { init } = location.state
  const { control, setValue, formState, getValues, reset, user } = useFormContext()
  const [roadBadge, setRoadBadge] = useState(false)
  const [unitsBadge, setUnitsBadge] = useState(0)
  const [peopleBadge, setPeopleBadge] = useState(0)
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)
  const [unitFormList, setUnitFormList] = useState([])
  const [lastUnitFormIndex, setlastUnitFormIndex] = useState(0)
  const theme = useTheme()

  console.log('F CONT', formList, form, formModel, init.user, user)

  useEffect(() => { // create a list of units forms
    const unitFormList = formList.filter(form => /^units\.\d+$/.test(form))
    if (unitFormList.length) setUnitFormList(unitFormList)
    const currentUnitFormListIndex = unitFormList.indexOf(form.name)
    if (currentUnitFormListIndex !== -1) setlastUnitFormIndex(currentUnitFormListIndex)
  }, [formList, form.name])

  const { append, remove } = useFieldArray({
    control
    , name: form.type === 'units' || form.type === 'people'
      ? `${(formList[form.number] || []).split('.').slice(0, 2).join('.')}.people`
      : form.type
  })

  const openDialog = () => { setIsSaveDialogOpen(true) }

  const closeDialog = () => { setIsSaveDialogOpen(false) }

  const getFormCtxUserOrg = () => {
    if (init.user.type === 1 && Object.prototype.hasOwnProperty.call(user, 'doAsOrganization')) {
      return user.doAsOrganization
    }
    return init.user.organization
  }

  // children = Children.map(children, el => cloneElement(el, { submitRef, handleSubmit }))

  useEffect(() => {
    console.log('FORM STATE', formState)
    console.log('FORM VALUES', getValues())
    const form = getValues()
    if (Object.prototype.hasOwnProperty.call(form, 'units')) {
      setUnitsBadge(form.units.length)
      setRoadBadge(true)
      setPeopleBadge(form.units.map(unit => unit.people?.length).reduce((a, c) => a + c, 0))
    }
    if (Object.prototype.hasOwnProperty.call(form, 'people')) {
      setPeopleBadge(form.people.length)
    }
  }, [formState, getValues])

  let StyledDrawer
  if (isMobile) {
    StyledDrawer = styled(Drawer)(({ theme }) => ({
      '& .MuiDrawer-paper': {
        height   : `calc(100% - ${theme.mixins.toolbar.minHeight}px)`
        , width  : '100vw'
      }
    }))
  } else {
    StyledDrawer = styled(Drawer)(({ theme }) => ({
      position               : 'relative' // imp
      , width                : theme.mixins.formDrawerWidth
      , '& .MuiDrawer-paper' : {
        width        : theme.mixins.formDrawerWidth
        , height     : '100vh'
        , position   : 'absolute' // imp
        , transition : 'none !important'
      }
    }))
  }

  return (
    <StyledDrawer
      variant="persistent"
      open={open}
      anchor={isMobile ? 'bottom' : undefined}
    >
      <Box display='flex' flexDirection='column' p={1} pb={1.5}>
        <Typography width='100%' variant='button' fontWeight={700} textAlign='center'>
          <Trans>NEW CRASH RECORD</Trans>
        </Typography>
        <Typography textTransform='uppercase' width='100%' variant='h6' fontWeight={600} textAlign='center'>
          {`${form.displayName}${form.type === 'units' ? ' ' + (lastUnitFormIndex + 1) : ''}`}
        </Typography>
        <Box mt={1} display='flex' justifyContent='space-evenly' alignItems='center' width='100%'>
          <Badge
            color='success'
            overlap='circular'
            variant='dot'
            invisible={formState.submitCount === 0}
          >
            <IconButton
              sx={form.type === 'crash' ? { backgroundColor: '#ebebeb' } : {}}
              onClick={() => {
                if (form.type !== 'crash') {
                  submitRef.current.requestSubmit()
                  handleSetFormNumber(formList.indexOf('crash'))
                }
              }}
            >
              <InfoOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large' } />
            </IconButton>
          </Badge>
          {Object.prototype.hasOwnProperty.call(formModel, 'road') &&
            <Badge
            color='success'
            overlap='circular'
            variant='dot'
            invisible={!roadBadge}
          >
              <IconButton
                sx={form.type === 'road' ? { backgroundColor: '#ebebeb' } : {}}
                onClick={() => {
                  if (form.type !== 'road') {
                    submitRef.current.requestSubmit()
                    handleSetFormNumber(formList.indexOf('road'))
                  }
                }}
              >
              <EditRoadSharpIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
            </IconButton>
          </Badge>}
          {Object.prototype.hasOwnProperty.call(formModel, 'units') &&
            <Badge
              color='primary'
              overlap='circular'
              badgeContent={unitsBadge}
            >
              <IconButton
                sx={form.type === 'units' ? { backgroundColor: '#ebebeb' } : {}}
                onClick={() => {
                  submitRef.current.requestSubmit()
                  let nextUnitForm
                  const currentIndex = unitFormList.indexOf(form.name)
                  if (currentIndex !== -1) {
                    if (currentIndex + 1 < unitFormList.length) {
                      nextUnitForm = unitFormList[currentIndex + 1]
                    } else nextUnitForm = unitFormList[0]
                  } else {
                    nextUnitForm = unitFormList[lastUnitFormIndex]
                  }
                  handleSetFormNumber(formList.indexOf(nextUnitForm))
                }}
              >
                <DirectionsCarOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
              </IconButton>
            </Badge>}
          {Object.prototype.hasOwnProperty.call(formModel, 'people') &&
            <Badge
              color='primary'
              overlap='circular'
              badgeContent={peopleBadge}
            >
              <IconButton
                sx={form.type === 'people' ? { backgroundColor: '#ebebeb' } : {}}
                onClick={() => console.log('Click!')} // TODO
              >
                <PersonOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
              </IconButton>
            </Badge>}
          {!isMobile &&
            <IconButton onClick={() => {
              reset()
              handlePositioningState(false)
              handleFormOpen(false)
            }}>
              <ArrowBackIosNewIcon color='primary' fontSize='large' />
            </IconButton>
          }
        </Box>
      </Box>
      <Box overflow='auto'>
        {!!Object.keys(reporterData).length &&
          <Card variant="outlined" sx={{ m: 1, pt: 1, pb: 1, pr: 2, pl: 2 }} >
            {/* <CardContent sx={{ pb: 2 }}> */}
              <Typography sx={{ color: 'text.secondary', fontSize: 13 }}>
                <Trans>Reported by</Trans>
              </Typography>
              <Typography variant='h6' component='div'>
                {`${reporterData.name} ${reporterData.surname}`}
              </Typography>
              <Typography gutterBottom component='div'>
                {reporterData.department}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: 13 }}>
                <Trans>Telephone</Trans>
              </Typography>
              <Typography gutterBottom={!!reporterData.email} variant='h6' component='div'>
                {reporterData.phone}
              </Typography>
              {reporterData.email && <>
                <Typography sx={{ color: 'text.secondary', fontSize: 13 }}>
                  <Trans>email</Trans>
                </Typography>
                <Typography variant='h6' component='div'>
                  {reporterData.email}
                </Typography>
              </>}
            {/* </CardContent> */}
          </Card>
        }
        <Form
          token={token}
          isMobile={isMobile}
          submitRef={submitRef}
          formModel={formModel}
          formList={formList}
          form={form}
          // unitsFormArray={{ fields: unitsMethods.fields }}
          handleCrashSubmit={handleCrashSubmit}
          handleAppend={handleAppend}
          handleSave={''}
          images={images}
        />
        <Box display='flex' flexDirection='column' gap={1} mt={2} mb={2} pl={1} pr={1}>
          {isMobile && isLoading && <CircularProgress />}
          {form.type === 'crash' && getFormCtxUserOrg() === 2 &&
            <Button variant='contained' onClick={() => { handleAppend('people') }}>
              <AddIcon variant='person' />
            </Button>}
          {form.type === 'road' &&
            <Button variant='contained' onClick={() => { handleAppend('units') }}>
              <AddIcon variant='unit' />
            </Button>}
          {form.type === 'units' &&
            <>
              <Box display='flex' gap={1}>
                <Button fullWidth variant='contained' onClick={() => { handleAppend('units') }}>
                  <AddIcon variant='unit' />
                </Button>
                <Button color='error' fullWidth variant='contained' onClick={() => handleRemove('units', form)}>
                  <DeleteIcon />
                </Button>
              </Box>
              <Button variant='contained' onClick={() => { handleAppend('unitPeople', formList[form.number], append) }}>
                <AddIcon variant='person' />
              </Button>
            </>}
          {form.type === 'people' && getFormCtxUserOrg() !== 2 &&
            <Box display='flex' gap={1}>
              <Button fullWidth variant='contained' onClick={() => { handleAppend('unitPeople', formList[form.number], append) }}>
                <AddIcon variant='person' />
              </Button>
              <Button color='error' fullWidth variant='contained' onClick={() => handleRemove('unitPeople', form, remove)}>
                <DeleteIcon />
              </Button>
            </Box>}
          {form.type === 'people' && getFormCtxUserOrg() === 2 &&
            <Box display='flex' gap={1}>
              <Button fullWidth variant='contained' onClick={() => { handleAppend('people', formList[form.number], append) }} >
                <AddIcon variant='person' />
              </Button>
              <Button color='error' fullWidth variant='contained' onClick={() => handleRemove('people', form)}>
                <DeleteIcon />
              </Button>
            </Box>}
        </Box>
        <Box
          display='flex'
          mt={3}
          pr={1}
          pl={1}
          gap={1}
          mb={isMobile ? `calc(${theme.spacing(3)} + ${theme.mixins.toolbar.minHeight}px)` : 2}
        >
          {isMobile && isLoading
            ? <CircularProgress />
            : <>
                <Button
                  fullWidth
                  variant='contained'
                  disabled={form.number === 0}
                  onClick={() => {
                    submitRef.current.requestSubmit()
                    handleSetFormNumber('-')
                  }}
                >
                  <ArrowLeftIcon />
                </Button>
                <Button
                fullWidth
                variant='contained'
                disabled={!getValues().units?.length && !getValues().people?.length}
                onClick={() => {
                  setValue('isFinal', true)
                  // submitRef.current.requestSubmit()
                  openDialog()
                }}
                >
                  <SaveIcon />
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  disabled={form.number === formList.length - 1}
                  onClick={() => {
                    submitRef.current.requestSubmit()
                    handleSetFormNumber('+')
                  }}
                >
                  <ArrowRightIcon />
                </Button>
              </>}
        </Box>
      </Box>
      <Dialog open={isSaveDialogOpen} onClose={closeDialog}>
        <DialogTitle>
          <Trans>What do you want to do?</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              Save as draft will store the crash record temporarly
              <br />
              (it can be modified afterwards).
            </Trans>
            <br />
            <br />
            <Trans>
              Save as final will store the crash record permanently
              <br />
              (only an Administrator can amend it hereafter).
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='error' onClick={closeDialog}>Cancel</Button>
          <Button
            variant='outlined'
            color='success'
            onClick={() => {
              setValue('isDraft', true)
              setValue('isFinal', true)
              submitRef.current.requestSubmit()
              closeDialog()
            }}>
            <Trans>Save as draft</Trans>
          </Button>
          <Button
            variant='outlined'
            onClick={() => submitRef.current.requestSubmit()}
            autoFocus
          >
            <Trans>Save as final</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </StyledDrawer>
  )
}

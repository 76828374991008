import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans, msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useForm, FormProvider } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import {
  Alert
  , Box
  , Button
  , Card
  , CardHeader
  , CardContent
  , Collapse
  , Divider
  , IconButton
  , MenuItem
  , Paper
  , Skeleton
  , TextField
  , Typography
  , useTheme
} from '@mui/material/'
// import { red } from '@mui/material/colors'
import {
  DataGrid
  , gridClasses
  , GridToolbarContainer
  , GridToolbarExport
} from '@mui/x-data-grid'
import { BarChart } from '@mui/x-charts/BarChart'
import { LineChart } from '@mui/x-charts/LineChart'
import { PieChart } from '@mui/x-charts/PieChart'
import {
  blueberryTwilightPalette
  , mangoFusionPalette
  // , cheerfulFiestaPalette
} from '@mui/x-charts/colorPalettes'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListAltIcon from '@mui/icons-material/ListAlt'
import BarChartIcon from '@mui/icons-material/BarChart'
import useHttp from '../../hooks/useHttp'
import useLogout from '../../hooks/useLogout'
// import MobileTopNav from '../components/mobile/MobileTopNav'
import DesktopNav from '../../components/desktop/DesktopNav'
import FilterForm from '../../components/forms/StatsFilterForm'
import { getColumnsModel, getConfigOptions, mapObjectToLabels } from './utils'
import dayjs from 'dayjs'
import useWindowSize from '../../hooks/useWindowSize'
import { cyan, green, grey, orange, pink, red, yellow } from '@mui/material/colors'

/*
  - Crash
  Collected by
  Officer Name/Operator Name
  Officer Department/Operator Hospital
  Date of Filling <----> Until <---->
  Crash date <----> Until <---->
  Crash month
  Light conditions
  Crash type
  ADaMS crash id
  Officer position
  Officer number
  Report number
  Report date <----> Until <---->
  Crash week day
  Wheather conditions
  Vehicle runaway
  Severity
*/

// eslint-disable-next-line no-restricted-globals
const statsBaseUrl = new URL('/api/statistics', location)
// eslint-disable-next-line no-restricted-globals
const allCrashesBaseUrl = new URL('/api/crash/all', location)

const paginationModel = { page: 0, pageSize: 25 }

function TableSkel () {
  return ( // ? <CircularProgress sx={{ mt: 2 }} />
    <>
      <Skeleton width='100%' sx={{ mt: 2, fontSize: '2rem' }} />
      {Array.from({ length: 5 }).map((_, i) => <Skeleton key={i} width='100%' />)}
      {/* <Skeleton width='100%' sx={{ fontSize: '2rem' }} /> */}
    </>
  )
}

export default function Statistics ({ position, isMobile }) {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const { _ } = useLingui()
  const token = location.state?.token || null
  const init = useMemo(() => location.state?.init || {}, [location.state?.init])
  // const organizations = useMemo(() => location.state?.organizations || {}, [location.state?.organizations])
  const methods = useForm({
    defaultValues: useMemo(elements => elements, [])
  })
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { isLoading, error, sendRequest } = useHttp()
  const [formModel, setFormModel] = useState({})
  const [formList, setFormList] = useState([])
  const [columns, setColumns] = useState([])
  const [columnGroupingModel, setColumnGroupingModel] = useState([])
  const [rows, setRows] = useState([])
  const [message, setMessage] = useState('')
  const [totalCrashes, setTotalCrashes] = useState({})
  const [user, setUser] = useState({})
  const [crashes, setCrashes] = useState([])
  const submitRef = useRef()
  const [charts, setCharts] = useState([
    {
      apiNum               : 1
      , title              : _(msg`Collisions by severity`)
      , type               : 'bar' // [bar, line, pie]
      , data               : []
      , xLabel             : _(msg`Crash severity`)
      , xLabelStyle        : { transform: 'translateY(0.5rem)' }
      , yLabel             : _(msg`Number of crashes`)
      , tickPlacement      : 'middle'
      , tickLabelPlacement : 'middle'
      , colorMap           : {
        type     : 'ordinal'
        , colors : [grey[700], green.A400, yellow.A700, orange[800], red.A700]
      }
    }
    , {
      apiNum        : 2
      , title       : _(msg`Road traffic fatalities per year`)
      , type        : 'bar'
      , data        : []
      , xLabel      : _(msg`Year`)
      , xLabelStyle : { transform: 'translateY(0.5rem)' }
      , yLabel      : _(msg`Number of fatalities`)
      , colorMap    : {
        type   : 'ordinal'
        , colors : blueberryTwilightPalette('light')
      }
    }
    , {
      apiNum               : 3
      , title              : _(msg`Road traffic fatalities by gender`)
      , type               : 'bar'
      , data               : []
      , xLabel             : _(msg`Victim's gender`)
      , xLabelStyle        : { transform: 'translateY(0.5rem)' }
      , yLabel             : _(msg`Number of fatalities`)
      , tickPlacement      : 'middle'
      , tickLabelPlacement : 'middle'
      , colorMap           : {
        type   : 'ordinal'
        , colors : [grey[700], pink[200], cyan[400]]
      }
    }
    , {
      apiNum               : 4
      , title              : _(msg`Fatalities and serious injuries by road user type`)
      , type               : 'bar'
      , data               : []
      , xLabel             : _(msg`Type of road user`)
      , xLabelStyle        : { transform: 'translateY(0.5rem)' }
      , yLabel             : _(msg`Number of fatalities and serious injuries`)
      , tickPlacement      : 'middle'
      , tickLabelPlacement : 'middle'
      , colorMap           : {
        type   : 'ordinal'
        , colors : blueberryTwilightPalette('light')
      }
    }
    , {
      apiNum               : 5
      , title              : _(msg`Number of collisions by day of the week`)
      , type               : 'bar'
      , data               : []
      , xLabel             : _(msg`Day of the week`)
      , xLabelStyle        : { transform: 'translateY(75px)' }
      , yLabel             : _(msg`Number of crashes`)
      , tickPlacement      : 'middle'
      , tickLabelPlacement : 'middle'
      , colorMap           : {
        type   : 'ordinal'
        , colors : blueberryTwilightPalette('light')
      }
      , tickLabelStyle: {
        angle        : 45
        , textAnchor : 'start'
        , fontSize   : 12
      }
      , margin: { bottom: 120, right: 50 }
    }
    , {
      apiNum               : 6
      , title              : _(msg`Road functional class collision`)
      , type               : 'bar'
      , data               : []
      , xLabel             : _(msg`Road functional class`)
      , xLabelStyle        : { transform: 'translateY(75px)' }
      , yLabel             : _(msg`Number of crashes`)
      , tickPlacement      : 'middle'
      , tickLabelPlacement : 'middle'
      , colorMap           : {
        type     : 'ordinal'
        , colors : blueberryTwilightPalette('light')
      }
      , tickLabelStyle: {
        angle        : 45
        , textAnchor : 'start'
        , fontSize   : 12
      }
      , margin: { bottom: 120, right: 50 }
    }
    , {
      apiNum        : 7
      , title       : _(msg`Type of collision`)
      , type        : 'bar'
      , data        : []
      , xLabel      : _(msg`Type of collision`)
      , xLabelStyle : { transform: 'translateY(250px)' }
      , yLabel      : _(msg`Number of crashes`)
      , colorMap    : {
        type     : 'ordinal'
        , colors : mangoFusionPalette('light')
      }
      , tickPlacement      : 'middle'
      , tickLabelPlacement : 'middle'
      , tickLabelStyle     : {
        angle        : 45
        , textAnchor : 'start'
        , fontSize   : 12
      }
      , margin: { bottom: 300, right: 150 }
    }
  ])
  const [showSub, setShowSub] = useState('crashList')
  const windowSize = useWindowSize()

  const groupedRows = useMemo(() => {
    if (!rows?.length) {
      return []
    }
    const groupBy = Object.groupBy(rows, item => item?.['Crash id'])
    const toArray = Object.entries(groupBy || {})?.map(([k, v], i) => ([k, i % 2 === 0]))
    const reduced = toArray?.reduce((acc, [k, v]) => {
      return ({ ...acc, ...{ [k]: v } })
    }, {})
    return reduced
    // console.group('GROUPED ROWS')
    // console.log('rows grouped::', groupBy)
    // console.log('rows toArray::', toArray)
    // console.log('reduced::', reduced)
    // console.groupEnd('GROUPED ROWS')
  }, [rows])

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    border                           : 0
    , [`& .${gridClasses.row}.even`] : {
      backgroundColor: theme.palette.grey[200]
    }
    , '& .MuiDataGrid-menuIcon > *': {
      color: theme.palette.primary.contrastText
    }
    , '& .MuiDataGrid-iconButtonContainer > *': {
      color: theme.palette.primary.contrastText
    }
  }))

  const ExpandMore = styled(props => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme }) => ({
    marginLeft : 'auto'
    , transition : theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
    , variants: [
      {
        props : ({ expand }) => !expand
        , style : {
          transform: 'rotate(0deg)'
        }
      }
      , {
        props : ({ expand }) => !!expand
        , style : {
          transform: 'rotate(180deg)'
        }
      }
    ]
  }))

  const TopToolbar = ({ totalCrashes }) => {
    return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <GridToolbarExport />
      <Typography align='right'>
        <Trans>Crashes found</Trans>: {totalCrashes}
      </Typography>
    </GridToolbarContainer>
    )
  }

  const getCrashes = useCallback(data => {
    const allCrashesUrl = new URL(allCrashesBaseUrl)
    allCrashesUrl.searchParams.set('format', 'table')
    if (data) {
      Object.entries(data).forEach(([key, value]) => allCrashesUrl.searchParams.append(key, value))
    }

    sendRequest({
      token
      , url           : allCrashesUrl
      , manageResData : res => {
        // Sorting and Adding id to each crash
        const sortedCrashes = res.crashes
          // .filter(crash => crash['Crash id'] !== '20241002_00001')
          .sort((a, b) => a['Crash id'] - b['Crash id'])
          .map((crash, i) => ({ ...crash, id: i + 1 }))

        setCrashes(sortedCrashes)
      }
    })
  }, [token, sendRequest])

  useEffect(() => {
    if (!token) return logout()
    if (isMobile) return navigate('/home', { state: { token, init } })
    getCrashes()
  }, [token, logout, isMobile, navigate, init, getCrashes])

  const handleFilterObjSubmit = data => {
    const subSection = data.SubSection
    let searchParams = {}
    delete data.SubSection
    Object.keys(data)
      .forEach(section => {
        Object.entries(data[section]).forEach(([key, value]) => {
          if (value !== undefined) {
            if (key.includes('To') || key.includes('From')) {
              const label = key.split('-')
              searchParams = { ...searchParams, [`${section}.${label[0]}.${label[1].toLowerCase()}`]: dayjs(value).toJSON() }
            } else {
              searchParams = { ...searchParams, [`${section}.${key}.equal`]: value }
            }
          }
        }
        )
      })

    if (subSection === 'crashes') {
      getCrashes(searchParams)
    } else {
      getCharts(searchParams)
    }
  }

  useEffect(() => {
    const { configuration, organizations, user, totalCrashes } = init
    setUser(user)
    setTotalCrashes(totalCrashes)

    const configOptions = getConfigOptions(configuration, organizations)

    const mappedCrashes = crashes.map(crash => mapObjectToLabels(crash, configOptions))

    const preparedRows = mappedCrashes.map(crash => ({
      ...crash.road
      , ...crash.unit
      , ...crash.unit.people
      , ...crash.people
      , ...crash.crash
      , ...crash
    }))

    setRows(preparedRows)

    const { columns, columnGroupingModelWOrg: columnGroupingModel } = getColumnsModel(configuration)

    console.log('GM', columnGroupingModel, 'COLS', columns)
    setColumns(columns)
    setColumnGroupingModel(columnGroupingModel)

    setFormModel(configuration)
    const initForms = Object.keys(configuration)
      .sort((a, b) => configuration[a].position - configuration[b].position)

    setFormList(initForms)
  }, [init, crashes])

  const getCharts = useCallback(data => {
    // 1 collisions by severity data
    // 2 road traffic fatalities per year data
    // 3 road traffic fatalities by gender data
    // 4 Fatalities and serious injuries by road user type data
    // 5 number of collisions by day of the week data
    // 6 Road functional class collision data
    // 7 Type of collision data
    const statsUrl = new URL(statsBaseUrl)

    charts
      .map(chart => chart.apiNum)
      .forEach(apiNum => {
        statsUrl.searchParams.set('type', apiNum)
        if (data) {
          Object.entries(data).forEach(([key, value]) => statsUrl.searchParams.append(key, value))
        }
        sendRequest({
          token
          , url           : statsUrl
          , manageResData : res => {
            console.log('STATS', apiNum, res)
            setCharts(old => old.map(el => el.apiNum === res.type ? ({ ...el, data: res.data }) : el))
          }
        })
      })
  }, [token, sendRequest])

  useEffect(() => { getCharts() }, [getCharts])

  const subPanelElements = [
    {
      label       : t`Crash list`
      , icon     : <ListAltIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : () => setShowSub('crashList')
    }
    , {
      label       : t`Charts`
      , icon     : <BarChartIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : () => setShowSub('charts')

    }
  ]

  function FilterPanel ({ title, children }) {
    const [expanded, setExpanded] = useState(formList.map(() => false))

    const handleExpandClick = number => {
      setExpanded(old => old.map((o, i) => i === number ? !o : o))
    }

    console.log('CHARTS', charts)
    return (
      <Paper square sx={{ display: 'flex', flexDirection: 'column', mt: 1, gap: 2, pt: 3, pb: 3, pl: 2, pr: 2 }}>
        <Box display='flex' justifyContent='space-between' alignItems='baseline'>
          <Typography variant="h5">
            <Trans>Filter</Trans>
          </Typography>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              methods.reset()
              getCrashes()
            }}
          >
            Reset
          </Button>
        </Box>
        <Divider flexitem sx={{ mt: -1 }}/>
        <FormProvider {...methods} {...user}>
        {formList.map((item, formNumber) => (
          // <FilterPanel key={item} title={formModel[formList[formNumber]].displayName}>
          <Card key={item} sx={{ minWidth: '50%' }}>
            <CardHeader
              action={
                <ExpandMore
                  expand={expanded[formNumber]}
                  onClick={() => handleExpandClick(formNumber)}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              }
              title={formModel[formList[formNumber]].displayName}
              sx={{ bgcolor: 'primary' }}
            />
            <Collapse in={expanded[formNumber]} timeout="auto" unmountOnExit>
              <CardContent>
                {/* {children} */}
                <FilterForm
                  title={title}
                  isMobile={isMobile}
                  formModel={formModel}
                  submitRef={submitRef}
                  formList={formList}
                  form={{
                    number        : formNumber
                    , type        : formList[formNumber]
                    , displayName : formModel[formList[formNumber]].displayName
                  }}
                  handleFilterObjSubmit={handleFilterObjSubmit}
                />
              </CardContent>
            </Collapse>
          </Card>
          // </FilterPanel>
        ))}
        </FormProvider>
        <Button variant='contained' onClick={() => {
          if (submitRef.current) return submitRef.current.requestSubmit()
        }}>
          Filter
        </Button>
      </Paper>
    // <Card sx={{ minWidth: '50%' }}>
    //   <CardHeader
    //     action={
    //       <ExpandMore
    //         expand={expanded}
    //         onClick={handleExpandClick}
    //         aria-expanded={expanded}
    //         aria-label="show more"
    //       >
    //         <ExpandMoreIcon />
    //       </ExpandMore>
    //     }
    //     title={title}
    //     sx={{ bgcolor: 'primary' }}
    //   />
    //   <Collapse in={expanded} timeout="auto" unmountOnExit>
    //     <CardContent>
    //       {children}
    //     </CardContent>
    //   </Collapse>
    // </Card>
    )
  }

  return (
    <Box component="main">
      <DesktopNav position={position} subPanelElements={subPanelElements} />
      <Box>
          {
            {
              crashList:
                <>
                  <Box
                    ml={`${drawerWidth}px`}
                    mt={2}
                    pl={2}
                    pr={2}
                    display='flex'
                    flexDirection='column'
                    alignItems='stretch'
                    gap={3}
                    sx={{
                      '& .overload-theme--header': {
                        backgroundColor   : theme.palette.primary.main
                        , color           : theme.palette.primary.contrastText
                      }
                    }}
                  >
                    <Typography sx={{ mt: 2 }} variant="h3">
                      <Trans>Crash list</Trans>
                    </Typography>
                    <Divider flexItem />
                    <FilterPanel title={'crashes'}/>
                    {/* <Paper square sx={{ display: 'flex', flexDirection: 'column', mt: 1, gap: 2, pt: 3, pb: 3, pl: 2, pr: 2 }}>
                      <Box display='flex' justifyContent='space-between' alignItems='baseline'>
                        <Typography variant="h5">
                          <Trans>Filter</Trans>
                        </Typography>
                        <Button
                          variant='contained'
                          size='small'
                          onClick={() => {
                            methods.reset()
                            getCrashes()
                          }}
                        >
                          Reset
                        </Button>
                      </Box>
                      <Divider flexitem sx={{ mt: -1 }}/>
                      <FormProvider {...methods} {...user}>
                      {formList.map((item, formNumber) => (
                        <FilterPanel key={item} title={formModel[formList[formNumber]].displayName}>
                          <FilterForm
                            isMobile={isMobile}
                            formModel={formModel}
                            submitRef={submitRef}
                            formList={formList}
                            form={{
                              number        : formNumber
                              , type        : formList[formNumber]
                              , displayName : formModel[formList[formNumber]].displayName
                            }}
                            handleFilterObjSubmit={handleFilterObjSubmit}
                          />
                        </FilterPanel>
                      ))}
                      </FormProvider>
                      <Button variant='contained' onClick={() => {
                        if (submitRef.current) return submitRef.current.requestSubmit()
                      }}>
                        Filter
                      </Button>
                    </Paper> */}
                    {isLoading
                      ? <TableSkel />
                      : <Paper sx={{ mb: 2, display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                          <StripedDataGrid
                            showColumnVerticalBorder
                            showCellVerticalBorder
                            unstable_rowSpanning
                            rows={rows}
                            columns={columns}
                            columnGroupingModel={columnGroupingModel}
                            initialState={{
                              pagination   : { paginationModel }
                              , columns    : { columnVisibilityModel: { id: false } }
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            getRowClassName={params => {
                              return groupedRows?.[params?.row?.['Crash id']] ? 'even' : 'odd'
                            }}
                            slots={{
                              toolbar: TopToolbar
                            }}
                            slotProps={{
                              toolbar: { totalCrashes: totalCrashes.total }
                            }}
                          />
                      </Paper>
                    }
                  </Box>
                  {error && <Alert sx={{ mt: 2 }} severity="error">
                    <Trans>There was an error: {error}.</Trans>
                  </Alert>}
                  {message && <Alert sx={{ mt: 2 }} severity='success'>
                    {message}
                  </Alert>}
                </>
              , charts:
                <Box
                  ml={`${drawerWidth}px`}
                  mt={2}
                  pl={2}
                  pr={2}
                  display='flex'
                  flexDirection='column'
                  alignItems='stretch'
                  gap={3}
                >
                  <Typography sx={{ mt: 2 }} variant="h3">
                    <Trans>Charts</Trans>
                  </Typography>
                  <Divider flexItem />
                  <FilterPanel title={'charts'}/>
                  {/* <Paper square sx={{ display: 'flex', flexDirection: 'column', mt: 1, gap: 2, pt: 3, pb: 3, pl: 2, pr: 2 }}>
                    <Box display='flex' justifyContent='space-between' alignItems='baseline'>
                      <Typography variant="h5">
                        <Trans>Filter</Trans>
                      </Typography>
                      <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                          methods.reset()
                          getCrashes()
                        }}
                      >
                        Reset
                      </Button>
                    </Box>
                    <Divider flexitem sx={{ mt: -1 }}/>
                    <FormProvider {...methods} {...user}>
                    {formList.map((item, formNumber) => (
                      <FilterPanel key={item} title={formModel[formList[formNumber]].displayName}>
                        <FilterForm
                          isMobile={isMobile}
                          formModel={formModel}
                          submitRef={submitRef}
                          formList={formList}
                          form={{
                            number        : formNumber
                            , type        : formList[formNumber]
                            , displayName : formModel[formList[formNumber]].displayName
                          }}
                          handleFilterObjSubmit={handleFilterObjSubmit}
                        />
                      </FilterPanel>
                    ))}
                    </FormProvider>
                    <Button variant='contained' onClick={() => {
                      if (submitRef.current) return submitRef.current.requestSubmit()
                    }}>
                      Filter
                    </Button>
                  </Paper> */}
                  {charts
                    .filter((_, index) => index !== charts.length - 1 || index % 2 > 0) // Filtering the last element only if it is odd
                    .map((chart, index) => {
                      if (index % 2 === 0) {
                        return (
                          <Box p={2} key={chart.title} width='100%' display='flex'>
                            <Box width='50%' display='flex' flexDirection='column' alignItems='center'>
                              <Typography align='center' variant='h6' width={400}>
                                <Trans>{chart.title}</Trans>
                              </Typography>
                              <Box width='100%' position='relative' mt={1} mb={-2}>
                                <TextField
                                  select
                                  size='small'
                                  value={chart.type}
                                  onChange={event => setCharts(old => old.map(el => el.apiNum === index + 1
                                    ? ({ ...el, type: event.target.value })
                                    : el
                                  ))}
                                  label="Chart type"
                                  sx={{ minWidth: 100, float: 'right', mr: 2 }}
                                >
                                  <MenuItem value="line"><Trans>line</Trans></MenuItem>
                                  <MenuItem value="bar"><Trans>bar</Trans></MenuItem>
                                  <MenuItem value="pie"><Trans>pie</Trans></MenuItem>
                                </TextField>
                              </Box>
                              {
                                {
                                  bar: <BarChart
                                    xAxis={[
                                      {
                                        data               : chart.data.map(el => el.label)
                                        , scaleType          : 'band'
                                        , label              : chart.xLabel
                                        , labelStyle         : chart.xLabelStyle
                                        , colorMap           : chart.colorMap
                                        , tickPlacement      : chart.tickPlacement
                                        , tickLabelPlacement : chart.tickLabelPlacement
                                        , tickLabelStyle     : chart.tickLabelStyle

                                      }
                                    ]}
                                    yAxis={[{ label: chart.yLabel, labelStyle: chart.yLabelStyle }]}
                                    series={[{ data: chart.data.map(el => el.value) }]}
                                    height={windowSize.height * 0.4 + (chart.margin?.bottom ?? 0)}
                                    margin={chart.margin}
                                    sx={{
                                      [`.${axisClasses.left} .${axisClasses.label}`]: {
                                      // Move the y-axis label with CSS
                                        transform: 'translateX(-0.5rem)'
                                      }
                                    }}
                                  />
                                  , line: <LineChart
                                    xAxis={[
                                      {
                                        data             : chart.data.map(el => el.label)
                                        , scaleType      : 'band'
                                        , label          : chart.xLabel
                                        , labelStyle     : chart.xLabelStyle
                                      }
                                    ]}
                                    yAxis={[{ label: chart.yLabel, labelStyle: chart.yLabelStyle }]}
                                    series={[{ data: chart.data.map(el => el.value) }]}
                                    height={windowSize.height * 0.4 + (chart.margin?.bottom ?? 0)}
                                    margin={chart.margin}
                                    sx={{
                                      [`.${axisClasses.left} .${axisClasses.label}`]: {
                                      // Move the y-axis label with CSS
                                        transform: 'translateX(-0.5rem)'
                                      }
                                    }}
                                  />
                                  , pie: <PieChart
                                    xAxis={[
                                      {
                                        data         : chart.data.map(el => el.label)
                                        , scaleType  : 'band'
                                        , label      : chart.xLabel
                                        , labelStyle : chart.xLabelStyle
                                      }
                                    ]}
                                    yAxis={[{ label: chart.yLabel, labelStyle: charts[index].yLabelStyle }]}
                                    series={[{ data: chart.data.map(el => el.value) }]}
                                    height={windowSize.height * 0.4 + (chart.margin?.bottom ?? 0)}
                                    margin={chart.margin}
                                    sx={{
                                      [`.${axisClasses.left} .${axisClasses.label}`]: {
                                      // Move the y-axis label with CSS
                                        transform: 'translateX(-0.5rem)'
                                      }
                                    }}
                                  />
                                }[charts[index].type]
                              }
                            </Box>
                            {charts[index + 1] &&
                              <Box width='50%' display='flex' flexDirection='column' alignItems='center'>
                                <Typography align='center' variant='h6' width={400}>
                                  <Trans>{charts[index + 1].title}</Trans>
                                </Typography>
                                <Box width='100%' position='relative' mt={1} mb={-2}>
                                  <TextField
                                    select
                                    size='small'
                                    value={charts[index + 1].type}
                                    onChange={event => setCharts(old => old.map(el => el.apiNum === index + 2
                                      ? ({ ...el, type: event.target.value })
                                      : el
                                    ))}
                                    label="Chart type"
                                    sx={{ minWidth: 100, float: 'right', mr: 2 }}
                                  >
                                    <MenuItem value="line"><Trans>line</Trans></MenuItem>
                                    <MenuItem value="bar"><Trans>bar</Trans></MenuItem>
                                    <MenuItem value="pie"><Trans>pie</Trans></MenuItem>
                                  </TextField>
                                </Box>
                                {
                                  {
                                    bar: <BarChart
                                      xAxis={[
                                        {
                                          data                 : charts[index + 1].data.map(el => el.label)
                                          , scaleType          : 'band'
                                          , label              : charts[index + 1].xLabel
                                          , labelStyle         : charts[index + 1].xLabelStyle
                                          , colorMap           : charts[index + 1].colorMap
                                          , tickPlacement      : charts[index + 1].tickPlacement
                                          , tickLabelPlacement : charts[index + 1].tickLabelPlacement
                                          , tickLabelStyle     : charts[index + 1].tickLabelStyle
                                        }
                                      ]}
                                      yAxis={[{
                                        label        : charts[index + 1].yLabel
                                        , labelStyle : charts[index + 1].yLabelStyle
                                      }]}
                                      series={[{ data: charts[index + 1].data.map(el => el.value) }]}
                                      height={windowSize.height * 0.4 + (charts[index + 1].margin?.bottom ?? 0)}
                                      margin={charts[index + 1].margin}
                                      sx={{
                                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                                        // Move the y-axis label with CSS
                                          transform: 'translateX(-0.5rem)'
                                        }
                                      }}
                                    />
                                    , line: <LineChart
                                      xAxis={[
                                        {
                                          data         : charts[index + 1].data.map(el => el.label)
                                          , scaleType  : 'band'
                                          , label      : charts[index + 1].xLabel
                                          , labelStyle : charts[index + 1].xLabelStyle
                                        }
                                      ]}
                                      yAxis={[{
                                        label        : charts[index + 1].yLabel
                                        , labelStyle : charts[index + 1].yLabelStyle
                                      }]}
                                      series={[{ data: charts[index + 1].data.map(el => el.value) }]}
                                      height={windowSize.height * 0.4 + (charts[index + 1].margin?.bottom ?? 0)}
                                      margin={charts[index + 1].margin}
                                      sx={{
                                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                                        // Move the y-axis label with CSS
                                          transform: 'translateX(-0.5rem)'
                                        }
                                      }}
                                    />
                                    , pie: <PieChart
                                      xAxis={[
                                        {
                                          data         : charts[index + 1].data.map(el => el.label)
                                          , scaleType  : 'band'
                                          , label      : charts[index + 1].xLabel
                                          , labelStyle : charts[index + 1].xLabelStyle
                                        }
                                      ]}
                                      yAxis={[{
                                        label        : charts[index + 1].yLabel
                                        , labelStyle : charts[index + 1].yLabelStyle
                                      }]}
                                      series={charts[index + 1].data.map(el => ({ data: [el.value] }))}
                                      height={windowSize.height * 0.4 + (charts[index + 1].margin?.bottom ?? 0)}
                                      margin={charts[index + 1].margin}
                                      sx={{
                                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                                        // Move the y-axis label with CSS
                                          transform: 'translateX(-0.5rem)'
                                        }
                                      }}
                                    />
                                  }[charts[index + 1].type]
                                }
                              </Box>
                            }
                          </Box >
                        )
                      }
                      return null
                    })
                  }
                  {charts.length % 2 > 0 &&
                    <Box width='100%' display='flex' flexDirection='column' alignItems='center' p={2}>
                      <Typography align='center' variant='h6' width={400}>
                        <Trans>{charts[charts.length - 1].title}</Trans>
                      </Typography>
                      <Box width='100%' position='relative' mt={1} mb={-2}>
                        <TextField
                          select
                          size='small'
                          value={charts[charts.length - 1].type}
                          onChange={event => setCharts(old => old.map(el => el.apiNum === charts.length
                            ? ({ ...el, type: event.target.value })
                            : el
                          ))}
                          label="Chart type"
                          sx={{ minWidth: 100, float: 'right', mr: `${16 + charts[charts.length - 1].margin?.right ?? 0}px` }}
                        >
                          <MenuItem value="line"><Trans>line</Trans></MenuItem>
                          <MenuItem value="bar"><Trans>bar</Trans></MenuItem>
                          <MenuItem value="pie"><Trans>pie</Trans></MenuItem>
                        </TextField>
                      </Box>
                      {
                        {
                          bar: <BarChart
                            xAxis={[
                              {
                                data                 : charts[charts.length - 1].data.map(el => el.label)
                                , scaleType          : 'band'
                                , label              : charts[charts.length - 1].xLabel
                                , labelStyle         : charts[charts.length - 1].xLabelStyle
                                , colorMap           : charts[charts.length - 1].colorMap
                                , tickPlacement      : charts[charts.length - 1].tickPlacement
                                , tickLabelPlacement : charts[charts.length - 1].tickLabelPlacement
                                , tickLabelStyle     : charts[charts.length - 1].tickLabelStyle
                              }
                            ]}
                            yAxis={[{
                              label        : charts[charts.length - 1].yLabel
                              , labelStyle : charts[charts.length - 1].yLabelStyle
                            }]}
                            series={[{ data: charts[charts.length - 1].data.map(el => el.value) }]}
                            height={windowSize.height * 0.4 + (charts[charts.length - 1].margin?.bottom ?? 0)}
                            margin={charts[charts.length - 1].margin}
                            sx={{ [`.${axisClasses.left} .${axisClasses.label}`]: { transform: 'translateX(-0.5rem)' } }}
                          />
                          , line: <LineChart
                            xAxis={[
                              {
                                data        : charts[charts.length - 1].data.map(el => el.label)
                                , scaleType : 'band'
                              }
                            ]}
                            series={[{ data: charts[charts.length - 1].data.map(el => el.value) }]}
                            height={windowSize.height * 0.4 + (charts[charts.length - 1].margin?.bottom ?? 0)}
                            margin={charts[charts.length - 1].margin}
                            sx={{ [`.${axisClasses.left} .${axisClasses.label}`]: { transform: 'translateX(-0.5rem)' } }}
                          />
                          , pie: <PieChart
                            xAxis={[
                              {
                                data        : charts[charts.length - 1].data.map(el => el.label)
                                , scaleType : 'band'
                              }
                            ]}
                            series={charts[charts.length - 1].data.map(el => ({ data: [el.value] }))}
                            height={windowSize.height * 0.4 + (charts[charts.length - 1].margin?.bottom ?? 0)}
                            margin={charts[charts.length - 1].margin}
                            sx={{ [`.${axisClasses.left} .${axisClasses.label}`]: { transform: 'translateX(-0.5rem)' } }}
                          />
                        }[charts[charts.length - 1].type]
                      }
                    </Box>
                 }
                </Box>
            }[showSub]
          }
      </Box>
      {error && <Alert sx={{ mt: 2 }} severity="error">
        <Trans>There was an error: {error}.</Trans>
      </Alert>}
      {message && <Alert sx={{ mt: 2 }} severity='success'>
        {message}
      </Alert>}
    </Box>
  )
}

// useEffect(() => {
//   let confSections = {}
//   Object.keys(configuration)
//     .forEach(key => {
//       confSections = {
//         ...confSections
//         , [key]: configuration[key].elements
//           .map((element, i) => ({ ...element, id: i + 1, section: key }))
//       }
//     })
//   console.log('CONF', confSections)
//   setRows(confSections.crash)
// }, [configuration])

// , rowSpanValueGetter : (value, row, params) => {
//   // const totalUnits = row.units.length
//   // const totalRows = row.units.reduce((acc, unit) => acc + unit.people.length, 0) // Total rows for each crash
//   // console.log('TR', totalRows)
//   let span = 1
//   // if (section === 'crash' || section === 'road') return span
//   const currentId = row['Crash id']
//   const rowIndex = rows.indexOf(rows.find(row => { return row['Crash id'] === currentId }))
//   console.log('CII', rowIndex)

//   // Count how many consecutive rows share the same crashDate
//   for (let i = rowIndex + 1; i < rows.length; i++) {
//     const nextRowData = rows[i]
//     if (nextRowData['Crash id'] !== currentId) break
//     if (value !== nextRowData[params.field]) break
//     span++
//   }
//   console.log('SPAN', span)
//   return span
// }

// crashes.map(())
// const crashes = res.crashes.map(crash => ({ ...crash, id: crash['Crash id'] }))

// const groupedCrashes = Object.groupBy(res.crashes, ({ 'Crash id': crashId }) => crashId)
// console.log('GROUP', groupedCrashes)
// const enGroup = Object.entries(group).map(([k, v]) => )

// , rowSpanValueGetter : (value, row, params) => {
//   const span = 1
//   const currentId = row['Crash id']
//   const ccrows = preparedRows.filter(row => row['crash id'] === currentId)
//   const rowIndex = preparedRows.indexOf(preparedRows.find(row => { return row['Crash id'] === currentId }))
//   console.log('CII', rowIndex)

//   // Count how many consecutive rows share the same crashDate
//   // for (let i = rowIndex + 1; i < rows.length; i++) {
//   //   const nextRowData = rows[i]
//   //   if (nextRowData['Crash id'] !== currentId) break
//   //   span++
//   // }
//   // console.log('SPAN', span)
//   // return span
//   return ccrows.length || 1
// }
// if (row.id === 1) return 1
// if (preparedRows[row.id - 2]['Crash id'] === row['Crash id']) return ''
// return value
// }

// let span = 1
// Count how many consecutive rows share the same crashDate
// for (let i = rowIndex + 1; i < rows.length; i++) {
//   const nextRowData = rows[i]
//   if (nextRowData.unit['Unit id'] !== currentId) break
//   span++
// }
// return span

//   const nextUnitIndex = preparedRows.indexOf(preparedRows.find(row => row.unit['Unit id'] === currentUnitId)) + 1

//   const rowIndex = preparedRows.indexOf(preparedRows.find(row => row.unit['Unit id'] === currentUnitId))
//   let spanIndexes = []
//   // Count how many consecutive rows share the same crashDate
//   for (let i = rowIndex + 1; i < rows.length; i++) {
//     const nextRowData = rows[i]
//     if (nextRowData['Unit id'] !== currentUnitId) break
//     if (value !== nextRowData[params.field]) break
//     spanIndexes = [...spanIndexes, nextRowData.id]
//   }
//   console.log('U_SPAN', spanIndexes)
//   return spanIndexes
// }

//     if (nextUnitIndex < preparedRows.length && preparedRows[nextUnitIndex + 1].unit['Unit id'] !== row['Unit id']) {
//     }
//     return value

//   const nextCrashIndex = preparedRows.indexOf(preparedRows.find(row => row.unit['Crash id'] === currentCrashId)) + 1

//   const rowIndex = preparedRows.indexOf(preparedRows.find(row => row['Crash id'] === currentCrashId))
//   let spanIndexes = []
//   // Count how many consecutive rows share the same crashDate
//   for (let i = rowIndex + 1; i < rows.length; i++) {
//     const nextRowData = rows[i]
//     if (nextRowData['Crash id'] !== currentCrashId) break
//     if (value !== nextRowData[params.field]) break
//     spanIndexes = [...spanIndexes, nextRowData.id]
//   }
//   console.log('C_SPAN', spanIndexes)
//   return spanIndexes
// }

//   if (nextCrashIndex < preparedRows.length && preparedRows[nextCrashIndex]['Crash id'] !== row['Crash id']) {
//   }
//   return value

// CHARTS
// <ChartContainer width={400} height={400}
//   series={
//     charts[index].data.map(el => ({ data: el.value }))
//   }
//   xAxis={[
//     {
//       data        : charts[index].data.map(el => el.label)
//       , scaleType : 'band'
//     }
//   ]}
// >
//   <BarPlot />
//   <LinePlot />
//   <ChartsXAxis label="X axis" position="bottom" axisId="x-axis-id" />
// {/* </ResponsiveChartContainer> */}
// </ChartContainer>
// <ResponsiveChartContainer
//   series={
//     charts[index + 1].data.map(el => ({ data: [el.value] }))
//   }
//   xAxis={[
//     {
//       data        : charts[index + 1].data.map(el => el.label)
//       , scaleType : 'band'
//       , scale     : 'band'
//     }
//   ]}
// >
//   <BarPlot />
//   <LinePlot />
//   <ChartsXAxis sx={{ width: '100%' }} label="X axis" position="bottom" axisId="x-axis-id" />
// </ResponsiveChartContainer>

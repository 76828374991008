import { Fragment, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { randStr } from '../../utils'
import {
  Badge
  , Box
  , Collapse
  , Divider
  , Drawer
  , FormControlLabel
  , List
  , ListItem
  , ListItemButton
  , ListItemIcon
  , ListItemText
  , styled
  , Switch
  , Toolbar
  , Typography, useTheme
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import HelpIcon from '@mui/icons-material/Help'
import InventoryIcon from '@mui/icons-material/Inventory'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp'
import useLogout from '../../hooks/useLogout'
import OrganizationIcon from '../OrganizationIcon'
import { Merge } from '@mui/icons-material'
import { useRoutes } from '../../store/routesContext'

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 30
    // , top     : 15
    // , border  : `2px solid ${theme.palette.background.paper}`
    // , padding: '0 4px'
  }
}))

export default function DesktopNav ({ position, subPanelElements }) {
  const routes = useRoutes()
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const location = useLocation()
  const { state = {}, state: { init, newLogsCount } = {} } = location
  const logout = useLogout()
  const navigate = useNavigate()
  const [switches, setSwitches] = useState(() => subPanelElements?.map(() => false))

  const handleListItemClick = (_, route) => {
    if (route === '/history') { // History is a component of home, not a page
      const isHistoryOpen = location.state?.isHistoryOpen ?? false
      navigate('/home', { state: { ...state, isHistoryOpen: !isHistoryOpen } })
    } else {
      navigate(route, { state: { ...state, isHistoryOpen: false } })
    }
  }

  const mainPanelElementsProps = [
    {
      label     : <Trans>GIS view & Data entry</Trans>
      , icon   : <HomeIcon color='primary' />
      , route   : '/home'
      , access : [0, 1, 2]
    }
    , {
      label     : <Trans>User profile</Trans>
      , icon   : <AccountCircleIcon color='primary' />
      , route   : '/user'
      , access : [0, 1, 2]
    }
    , {
      label     : <Trans>Admin</Trans>
      , icon   : <AdminPanelSettingsIcon color='primary' />
      , route   : '/admin'
      , access : [1, 2]
    }
    , {
      label     : <Trans>Statistics</Trans>
      , icon   : <InsertChartIcon color='primary' />
      , route   : '/statistics'
      , access : [0, 1, 2]
    }
    , {
      label     : <Trans>History</Trans>
      , icon   : <InventoryIcon color='primary' />
      , route  : '/history'
      , access : [1, 2]
      , badge  : newLogsCount
    }
    , {
      label     : <Trans>Help</Trans>
      , icon   : <HelpIcon color='primary' />
      , route   : '/help'
      , access : [0, 1, 2]
    }
    , {
      label     : <Trans>Data merging and validation</Trans>
      , icon   : <Merge color='primary' />
      , route   : '/tools'
      , access : [1, 2]
    }
  ]

  const mainPanelElements = routes.map(route =>
    mainPanelElementsProps.find(element => element.route === route) || { route }
  )

  return (
      <Box sx={{ display: 'flex' }}>
          <Drawer
            variant="permanent"
            sx={{
              width                  : drawerWidth
              , flexShrink           : 0
              , '& .MuiDrawer-paper' : { width: drawerWidth, boxSizing: 'border-box' }
            }}
          >
            <Toolbar sx={{ justifyContent: 'center', pt: 2, pb: 2 }} >
              <img src='../assets/logo_adams.png' alt='ADaMS logo' style={{ width: '75%' }} />
            </Toolbar>
            <Box sx={{ overflow: 'auto' }}>
              {Object.keys(init).length &&
                <>
                  <Box display='flex' justifyContent='center' mt={1} mb={2}>
                    {/* Nice jsx way of conditional (switch) rendering {
                      {
                        police    : <LocalPoliceOutlined sx={{ fontSize: 54 }} />
                        , hospital  : <LocalHospitalOutlined />
                        , insurance : <AccountBalanceOutlined />
                      }[user.organization]
                    } */}
                    <OrganizationIcon organization={init.user.organization} userType={init.user.type} style={{ color: '#4a4a4a', fontSize: 36 }} />
                  </Box>
                  <Typography variant='h6' align='center'>{init.user.name || init.user.surname ? `${init.user.name} ${init.user.surname}` : init.user.username}</Typography>
                  <Typography sx={{ mb: 2 }} align='center'>{init.user.department}</Typography>
                  {/* <Typography>{organizations.find(o => o.value === init.user.organization).label}</Typography> */}
                  <Divider />
                  <List>
                    {mainPanelElements
                      .filter(elem => elem.access.includes(init.user.type))
                      .map((elem, index) => (
                        <Fragment key={`${elem.label}-${randStr()}`}>
                          <ListItemButton // TODO
                            selected={ index === routes.map(route => route.path).indexOf('/history') || index === position}
                            onClick={() => handleListItemClick(null, elem.route)}
                            sx={{ width: '100%' }}
                          >
                            <StyledBadge badgeContent={elem.badge} color='error'>
                              <ListItemIcon>
                                {elem.icon}
                              </ListItemIcon>
                            </StyledBadge>
                            <ListItemText primary={elem.label} />
                          </ListItemButton>
                          <Collapse
                            in={position === index && !location.state?.isHistoryOpen} // Valid only if History has no subElems
                            timeout="auto"
                            unmountOnExit
                          >
                            {!!subPanelElements.length && <>
                              <List>
                                {subPanelElements.map((elem, index) => {
                                  if (elem.type === 'switch') {
                                    return (
                                      <ListItem key={elem.label} sx={{ pl: 4 }}>
                                        <FormControlLabel
                                          checked={switches[index]}
                                          control={<Switch />}
                                          label={elem.label}
                                          onChange={e => {
                                            setSwitches(old => old.map((o, i) => i === index ? e.target.checked : o))
                                            elem.action(e.target.checked)
                                          }}
                                        />
                                      </ListItem>
                                    )
                                  }
                                  if (elem.type === 'button') {
                                    return (
                                      <ListItem disablePadding key={`${elem.label}-${randStr()}`}>
                                        <ListItemButton onClick={elem.action} sx={{ pl: 4 }}>
                                          <ListItemIcon>
                                            {elem.icon}
                                          </ListItemIcon>
                                          <ListItemText primary={elem.label} />
                                        </ListItemButton>
                                      </ListItem>
                                    )
                                  }
                                  return ''
                                })}
                              </List>
                              <Divider />
                            </>}
                          </Collapse>
                        </Fragment>
                      ))}
                    <ListItem disablePadding>
                      <ListItemButton onClick={logout}>
                        <ListItemIcon>
                          <LogoutSharpIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={<Trans>Logout</Trans>} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                  <Divider />
              </>}
            </Box>
          </Drawer>
      </Box>
  )
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import maplibregl, { Popup, Marker, AttributionControl } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { Box, Chip, ClickAwayListener, Fade, IconButton, MenuItem, MenuList, Paper, Popper, Slide, useTheme } from '@mui/material'
import LayersIcon from '@mui/icons-material/Layers'
import GeneralInfo from '../GeneralInfo'
import { makePin } from './Pin'
import { SEVERITIES } from '../../constants'
import Copyright from '../Copyright'
import useWindowSize from '../../hooks/useWindowSize'
import { useLocation } from 'react-router-dom'
// import './markers.css'

const CENTER = [
  Number.parseFloat(process.env.REACT_APP_CENTER_LON)
  , Number.parseFloat(process.env.REACT_APP_CENTER_LAT)
] // LON, LAT Rome - This should be capital of the state
const ZOOM = 13
const PINSCALE = 0.75
const OFFSET = [0, -10]

const StyleControl = ({ map, zIndex }) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined

  const options = useMemo(() => [
    { name: 'Map', style: 'openstreetmap' }
    , { name: 'Urban', style: 'streets' }
    , { name: 'Satellite', style: 'satellite' }
    , { name: 'Hybrid', style: 'hybrid' }
  ], [])

  const handleMapStyleChange = useCallback(index => {
    return `https://api.maptiler.com/maps/${options[index].style}/style.json?key=${process.env.REACT_APP_API_KEY}`
  }, [options])

  return (
    <>
      <IconButton
        sx={{
          '&:hover': {
            backgroundColor: 'rgb(242 242 242)'
          }
          , WebkitTapHighlightColor : 'rgb(0 0 0/0)'
          , WebkitTextSizeAdjust    : '100%'
          , WebkitFontSmoothing     : 'antialiased'
          , color                   : 'rgba(0, 0, 0, 0.87)'
          , pointerEvents           : 'auto'
          , background              : '#fff'
          , borderRadius            : '4px'
          , float                   : 'right'
          , margin                  : '9.25rem 10px 0 0'
          , boxShadow               : '0 0 0 2px rgba(0,0,0,.1)'
          , padding                 : '3px'
          , fontSize                : '1.45rem'
          , lineHeight              : '20px'
          , zIndex
        }}
        aria-describedby={id}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select map layer"
        aria-haspopup="menu"
        disableRipple
        onClick={handleClick}
      >
        <LayersIcon fontSize='inherit' />
      </IconButton>
      <Popper
        id={id}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        placement='left'
        disablePortal
        modifiers={[
          {
            name    : 'offset'
            , options : {
              offset: [0, 10]
            }
          }
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: 'right'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="popper-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.style}
                      selected={index === selectedIndex}
                      onClick={() => {
                        setSelectedIndex(index)
                        map.setStyle(handleMapStyleChange(index))
                        setOpen(false)
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

const addHeatMap = (data, map) => {
  console.log('MMMMM', { type: 'geojson', data })
  map.addSource('crashes', { type: 'geojson', data })
  map.addLayer(
    {
      id        : 'crashes-heat'
      , type    : 'heatmap'
      , source  : 'crashes'
      , maxzoom : 20
      , paint   : {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': [
          'interpolate'
          , ['linear']
          , ['get', 'Crash severity']
          , 0
          , 0
          , 4
          , 1
        ]
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        , 'heatmap-intensity': [
          'interpolate'
          , ['linear']
          , ['zoom']
          , 0
          , 1
          , 9
          , 3
        ]
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparency color
        // to create a blur-like effect.
        , 'heatmap-color': [
          'interpolate'
          , ['linear']
          , ['heatmap-density']
          , 0
          , 'rgba(33,102,172,0)'
          , 0.2
          , 'rgb(96, 97, 97)'
          , 0.4
          , 'rgb(9, 208, 7)'
          , 0.6
          , 'rgb(221, 221, 0)'
          , 0.8
          , 'rgb(221, 126, 0)'
          , 1
          , 'rgb(221, 0, 0)'
        ]
        // Adjust the heatmap radius by zoom level
        , 'heatmap-radius': [
          'interpolate'
          , ['linear']
          , ['zoom']
          , 0
          , 2
          , 9
          , 20
        ]
        // Transition from heatmap to circle layer by zoom level
        // , 'heatmap-opacity': [
        //   'interpolate'
        //   , ['linear']
        //   , ['zoom']
        //   , 7
        //   , 1
        //   , 20
        //   , 0
        // ]
      }
    }
  )

  // map.addLayer(
  //   {
  //     id        : 'crashes-point'
  //     , type    : 'circle'
  //     , source  : 'crashes'
  //     , minzoom : 6
  //     , paint   : {
  //       // Size circle radius by earthquake magnitude and zoom level
  //       'circle-radius': [
  //         'interpolate'
  //         , ['linear']
  //         , ['zoom']
  //         , 7
  //         , ['interpolate', ['linear'], ['get', 'Crash severity'], 1, 1, 6, 4]
  //         , 16
  //         , ['interpolate', ['linear'], ['get', 'Crash severity'], 1, 5, 6, 50]
  //       ]
  //       // Color circle by earthquake magnitude
  //       , 'circle-color': [
  //         'interpolate'
  //         , ['linear']
  //         , ['get', 'Crash severity']
  //         // , 0
  //         // , 'rgba(33,102,172,0)'
  //         , 0
  //         , 'rgb(103,169,207)'
  //         , 1
  //         , 'rgb(209,229,240)'
  //         , 2
  //         , 'rgb(253,219,199)'
  //         , 3
  //         , 'rgb(239,138,98)'
  //         , 4
  //         , 'rgb(178,24,43)'
  //       ]
  //       , 'circle-stroke-color' : 'white'
  //       , 'circle-stroke-width' : 1
  //       // Transition from heatmap to circle layer by zoom level
  //       , 'circle-opacity': [
  //         'interpolate'
  //         , ['linear']
  //         , ['zoom']
  //         , 7
  //         , 0
  //         , 8
  //         , 1
  //       ]
  //     }
  //   }
  // )
}

const removeHeatMap = map => {
  map.removeLayer('crashes-heat')
  map.removeSource('crashes')
}

export default function MapLibre ({
  isMobile
  , isPositioning = false
  , isPositionSet
  , handlePositioningState
  , isFormOpen
  , isHistoryOpen
  , handleFormOpen
  , setCoordinates
  , showCrashes
  , allCrashes
  , crashesSummary
  , mapRef
  , showHeatMap
  , getCrash
  , crashInfo
}) {
  if (process.env.REACT_APP_API_KEY == null) {
    throw new Error('You have to configure env REACT_APP_API_KEY, see README')
  }

  const theme = useTheme()
  const location = useLocation()
  const barHeight = theme.mixins.toolbar.minHeight
  const drawerWidth = theme.mixins.drawerWidth
  const formDrawerWidth = theme.mixins.formDrawerWidth
  const zIndex = theme.zIndex.drawer
  const { init } = location.state
  const user = useMemo(() => init?.user, [init])
  const organizations = useMemo(() => init?.organizations, [init])
  const [map, setMap] = useState({})
  const [markers, setMarkers] = useState([])
  // const [newMarker, setNewMarker] = useState({})

  // Setting up the map
  useEffect(() => {
    const glMap = new maplibregl.Map({
      container            : mapRef.current
      , style              : `https://api.maptiler.com/maps/openstreetmap/style.json?key=${process.env.REACT_APP_API_KEY}`
      , center             : CENTER
      , zoom               : ZOOM
      , attributionControl : false
    })

    glMap.addControl(new maplibregl.NavigationControl(), 'top-right')
    glMap.addControl(new AttributionControl({ compact: isMobile }))
    // Initialize the geolocate control.
    const geolocate = new maplibregl.GeolocateControl({
      showAccuracyCircle   : isMobile
      , trackUserLocation  : isMobile
      , positionOptions    : {
        enableHighAccuracy: true
      }
    })

    // Add the control to the map.
    glMap.addControl(geolocate)
    // Set an event listener that fires
    // when a geolocate event occurs.
    geolocate.on('geolocate', event => {
      console.log('A geolocate event has occurred.', event)
    })

    // glMap.on('render', () => console.log(RRRRR'))
    glMap.on('load', () => {
      const attribution = document.getElementsByTagName('details')[0]
      if (attribution.classList.contains('maplibregl-compact-show')) {
        attribution.classList.remove('maplibregl-compact-show')
      }
      geolocate.trigger()
    })

    glMap.on('zoomend', () => console.log('MZZZ', glMap.getZoom()))

    setMap(glMap)

    return () => {
      glMap.remove()
      setMap({})
    }
  }, [isMobile, mapRef])

  const setMarker = useCallback((crash, isEditing) => {
    const fill = SEVERITIES().find(e => e.value === crash['Crash severity']).fill
    const elem = makePin(document, fill, PINSCALE)
    const popupContent = document.createElement('div')
    popupContent.style = 'display: flex; flex-direction: column; height:100%; align-items: center;'
    const h4 = document.createElement('h4')
    h4.style = 'margin: 0 0.5em;'
    const h3 = document.createElement('h3')
    h3.style = 'margin-top: 0;'
    const p1 = document.createElement('p')
    p1.style = 'margin-bottom: 0;'
    const p2 = document.createElement('p')
    p2.style = 'margin-bottom: 0; margin-top: 1em;'

    h3.innerHTML = crash['Crash id']
    h4.innerHTML = crash.merge_date ? 'Merged/Validated' : organizations?.find(org => org?.id === crash?.organization)?.description
    p1.innerHTML = 'Report type:'
    p2.innerHTML = 'Crash id:'

    popupContent.appendChild(p1)
    popupContent.appendChild(h4)
    popupContent.appendChild(p2)
    popupContent.appendChild(h3)
    if (!isEditing && (
      user.type === 1 ||
      (user.type === 2 && crash.organization === user.organization) ||
      crash.user_id === user.id
    )) {
      const editButton = document.createElement('button')
      editButton.style = 'background-color: transparent; border: 1px solid black; border-radius: 3px'
      editButton.id = 'edit-button'
      editButton.onclick = () => {
        if (crash.merge_date) {
          getCrash(crash['Crash id'], crash.organization)
        } else {
          getCrash(crash['Crash id'], crash.organization, crash.user_id)
        }
      }
      editButton.innerHTML = 'EDIT'
      popupContent.appendChild(editButton)
    }

    if (isEditing) {
      const editButton = document.getElementById('edit-button')
      if (editButton) editButton.style = 'display: none;'
    }

    const myPopup = new Popup()
    const marker = new Marker({ element: elem })
      .setLngLat([crash.Longitude, crash.Latitude])
      .setPopup(myPopup.addClassName('custom-popup').setDOMContent(popupContent))
      .addTo(map)

    setMarkers(old => [...old, { id: crash['Crash id'], marker }])
  }, [map, getCrash, organizations, user.organization, user.type])

  // Show all crashes
  useEffect(() => {
    if (showCrashes) {
      allCrashes.reduced.forEach(crash => setMarker(crash))
    } else { // if (markers?.length)
      console.log('MARK', markers, 'LEN', markers.length)
      markers?.forEach(elem => {
        elem.marker.remove()
        setMarkers(old => old.filter(e => e.id !== elem.id))
      })
    }

    return () => {
      markers.forEach(elem => {
        elem.marker.remove()
        setMarkers(old => old.filter(e => e.id !== elem.id))
      })
    }
  }, [showCrashes, allCrashes.reduced, map, setMarkers])

  // Show a crash
  useEffect(() => {
    if (isFormOpen && Object.keys(crashInfo).length) {
      setMarker(crashInfo, true)
      map.flyTo({ center: { lat: crashInfo.Latitude, lng: crashInfo.Longitude }, speed: 0.2 })
    }
  }, [isFormOpen, crashInfo, setMarker, map])

  // Show heatmap
  useEffect(() => {
    if (showHeatMap) addHeatMap(allCrashes.heat_map, map)
    else if (Object.keys(map).length && !!map.getLayer('crashes-heat')) removeHeatMap(map)
  }, [showHeatMap, allCrashes.heat_map, map])

  // Show cross on selecting position
  const NewCrashSelector = ({ isMobile, map, isPositioning, handlePositioningState }) => {
    const windowSize = useWindowSize()
    const cH = useRef(null)
    const cV = useRef(null)

    const transitionDuration = {
      enter   : theme.transitions.duration.enteringScreen
      , exit  : theme.transitions.duration.leavingScreen
    }

    const styles = {
      cH: {
        width  : '100%'
        , height : '1px'
      }
      , cV: {
        height : '100%'
        , width  : '1px'
        , top    : 0
      }
      , hair: {
        position        : 'absolute'
        , backgroundColor : '#000'
        , pointerEvents   : 'none'
        , zIndex          : zIndex - 1
      }
    }

    const onclick = useCallback(e => {
      console.log('LNGLAT', e.lngLat, 'EEE', e)
      const container = mapRef.current
      const newMarkers = container.getElementsByClassName('new-markers')
      console.log('NMNM', newMarkers)
      Array.from(newMarkers).forEach(marker => marker.remove())
      const fill = SEVERITIES()[0].fill
      const element = makePin(document, fill, PINSCALE, 'new-markers')
      const marker = new Marker({ element, offset: OFFSET, draggable: true })
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .addTo(map)

      marker.on('dragend', () => setCoordinates(marker.getLngLat()))

      // map.setCenter(e.lngLat)
      map.flyTo({ center: e.lngLat, speed: 0.1 })
      // setNewMarker(marker)
      setCoordinates(e.lngLat)
      handleFormOpen(true)
      handlePositioningState(false)
      map.off('click', onclick) // Important: remove the listener
    }, [map, handlePositioningState])

    useEffect(() => {
      const container = mapRef.current
      if (!isMobile && isPositioning) {
        const ch = cH.current
        const cv = cV.current
        container.onmousemove = e => {
          ch.style.top = `${e.clientY}px`
          cv.style.left = isFormOpen
            ? `${e.clientX - drawerWidth - formDrawerWidth}px`
            : `${e.clientX - drawerWidth}px`
        }
      }

      if (isPositioning) map.on('click', onclick)
    }, [isMobile, map, onclick, isPositioning])

    return (
      <>
        <Slide
          in={isPositioning && !isPositionSet}
          timeout={transitionDuration}
          // style={{
          //   transitionDelay: `${isPositioning ? transitionDuration.exit : 0}ms`
          // }}
          unmountOnExit
        >
          <Box
            position='absolute'
            top={isMobile ? '1rem' : '3rem'}
            // top={isMobile ? `calc(${barHeight}px + 1rem)` : '3rem'}
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            zIndex={isMobile ? zIndex - 1 : 99999}
          >
            <Chip label='Please select the new crash location' color='error' />
          </Box>
        </Slide>
        <Box position='absolute' top={0} left={0} width={windowSize.width - drawerWidth} height={windowSize.height}>
          <div ref={cH} style={{ ...styles.cH, ...styles.hair }}></div>
          <div ref={cV} style={{ ...styles.cV, ...styles.hair }}></div>
        </Box>
      </>
    )
  }

  useEffect(() => {
    const container = mapRef.current
    /* This changes the cursor style during positioning */
    const mapElem = [...container.children]
      .find(ch => ch.className.includes('canvas'))
    if (mapElem && isPositioning) {
      mapElem.style.cursor = 'none'
    } else {
      mapElem.style.cursor = 'grab'
    }
  }, [mapRef, isPositioning])

  return (
    <Box
      height={isMobile ? `calc(100vh - ${barHeight * 2}px)` : '100vh'}
      ml={!isMobile && isFormOpen ? `${formDrawerWidth}px` : 0}
      mr={!isMobile && isHistoryOpen ? `${formDrawerWidth}px` : 0}
    >
      <Box id='map-cont' ref={mapRef} width='100%' height='100%'>
        {isPositioning &&
          <NewCrashSelector
            isMobile={isMobile}
            isPositioning={isPositioning}
            handlePositioningState={handlePositioningState}
            map={map}
          />}
        <GeneralInfo crashesSummary={crashesSummary} />
        <Copyright bgcolor='hsla(0, 0%, 100%, .5)' left={0} bottom={0} />
        <StyleControl map={map} zIndex={zIndex - 1} />
      </Box>
    </Box>
  )
}

// Data format example
// const datam = {
//   type     : 'FeatureCollection'
//   , features : [
//     {
//       geometry: {
//         coordinates : [43.87889099121094, 11.182965278625488]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-09-26 01:01:28'
//         , 'Crash id'       : '20240926_00001'
//         , 'Crash severity' : 0
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.88935470581055, 11.110898971557617]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-09-26 01:07:51'
//         , 'Crash id'       : '20240926_00002'
//         , 'Crash severity' : 1
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.89260482788086, 11.183246612548828]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-09-29 22:16:29'
//         , 'Crash id'       : '20240929_00001'
//         , 'Crash severity' : 2
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.74894714355469, 11.182528495788574]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-09-30 00:33:49'
//         , 'Crash id'       : '20240930_00001'
//         , 'Crash severity' : 2
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.76396942138672, 11.248943328857422]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-09-30 14:19:52'
//         , 'Crash id'       : '20240930_00002'
//         , 'Crash severity' : 4
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [41.86672592163086, 12.464006423950195]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-02 19:49:20'
//         , 'Crash id'       : '20241002_00001'
//         , 'Crash severity' : 3
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.77414321899414, 11.221251487731934]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-11 09:47:15'
//         , 'Crash id'       : '20241011_00002'
//         , 'Crash severity' : 3
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.81095886230469, 11.255919456481934]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-11 17:39:48'
//         , 'Crash id'       : '20241011_00003'
//         , 'Crash severity' : 2
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.79914474487305, 11.188627243041992]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-11 09:33:06'
//         , 'Crash id'       : '20241011_00004'
//         , 'Crash severity' : 2
//       }
//       , type: 'Feature'
//     }, {
//       geometry: {
//         coordinates : [43.81861877441406, 11.271965026855469]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-22 09:28:31'
//         , 'Crash id'       : '20241022_00001'
//         , 'Crash severity' : 2
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.80424499511719, 11.279932022094727]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-22 09:53:39'
//         , 'Crash id'       : '20241022_00002'
//         , 'Crash severity' : 4
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [41.91130065917969, 12.509038925170898]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-22 15:58:14'
//         , 'Crash id'       : '20241022_00003'
//         , 'Crash severity' : 0
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [41.91135787963867, 12.509038925170898]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-22 15:58:27'
//         , 'Crash id'       : '20241022_00004'
//         , 'Crash severity' : 0
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [41.91136932373047, 12.509092330932617]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-22 15:58:54'
//         , 'Crash id'       : '20241022_00005'
//         , 'Crash severity' : 0
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [41.91136932373047, 12.509092330932617]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-22 15:58:54'
//         , 'Crash id'       : '20241022_00007'
//         , 'Crash severity' : 0
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [41.912986755371094, 12.506664276123047]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-22 16:32:51'
//         , 'Crash id'       : '20241022_00008'
//         , 'Crash severity' : 0
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.815921783447266, 11.152767181396484]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-10-27 12:34:43'
//         , 'Crash id'       : '20241027_00001'
//         , 'Crash severity' : 1
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.708335876464844, 11.136319160461426]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-11-06 22:36:10'
//         , 'Crash id'       : '20241106_00001'
//         , 'Crash severity' : 3
//       }
//       , type: 'Feature'
//     }
//     , {
//       geometry: {
//         coordinates : [43.911094665527344, 11.299306869506836]
//         , type        : 'Point'
//       }
//       , properties: {
//         'Crash date'     : '2024-11-14 16:42:14'
//         , 'Crash id'       : '20241114_00001'
//         , 'Crash severity' : 2
//       }
//       , type: 'Feature'
//     }]
// }

// , 0
// , 'rgba(33,102,172,0)'
// , 0.2
// , 'rgb(103,169,207)'
// , 0.4
// , 'rgb(209,229,240)'
// , 0.6
// , 'rgb(253,219,199)'
// , 0.8
// , 'rgb(239,138,98)'
// , 1
// , 'rgb(178,24,43)'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Badge
  , Box
  , Button
  , Card
  , CardActions, CardContent
  , CardHeader
  , Collapse
  , Divider
  , Drawer
  , IconButton
  , List
  , ListItem
  , ListItemText
  , Skeleton
  , Slide
  , Snackbar
  , styled
  , Typography
  , useTheme
} from '@mui/material/'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import FilterFormElement from '../components/forms/FilterFormElement'
import OrganizationIcon from '../components/OrganizationIcon'

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

// eslint-disable-next-line no-restricted-globals
const logsBaseUrl = new URL('/api/action_log', location)
// eslint-disable-next-line no-restricted-globals
const getUsersUrl = new URL('/api/users', location)

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width                : theme.mixins.formDrawerWidth
  , '& .MuiDrawer-paper' : {
    width        : theme.mixins.formDrawerWidth
    , height     : '100vh'
  }
}))

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': { top: 10 }
}))

const ExpandMore = styled(props => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme }) => ({
  marginLeft : 'auto'
  , transition : theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
  , variants: [
    {
      props : ({ expand }) => !expand
      , style : {
        transform: 'rotate(0deg)'
      }
    }
    , {
      props : ({ expand }) => !!expand
      , style : {
        transform: 'rotate(180deg)'
      }
    }
  ]
}))

const TableSkel = ({ length = 5 }) => ( // ? <CircularProgress sx={{ mt: 2 }} />
    <>
      <Skeleton width='100%' sx={{ mt: 2, fontSize: '2rem' }} />
      {Array.from({ length }).map((_, i) => <Skeleton key={i} width='100%' />)}
      {/* <Skeleton width='100%' sx={{ fontSize: '2rem' }} /> */}
    </>
)

export default function History ({ position, open, isMobile, getCrash }) {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const state = location.state
  const { token = null, lastLoginDate = null } = state
  // const token = location.state?.token || null
  // const init = useMemo(() => location.state?.init || {}, [location.state?.init])
  // const lastLoginDate = useMemo(() => location.state?.lastLoginDate ||
  const theme = useTheme()
  const { _ } = useLingui()
  const { isLoading, error, sendRequest } = useHttp()
  const [logs, setLogs] = useState([])
  const [expanded, setExpanded] = useState(false)
  const methods = useForm({
    defaultValues: useMemo(elements => elements, [])
  })
  const submitRef = useRef()
  const [users, setUsers] = useState([])

  const historyFilterElements = [
    {
      label        : 'Date'
      , isRequired : false
      , margin     : 'dense'
      , type       : 'date'
      , value      : ''
      , stack      : true
    }
    , {
      label        : 'User'
      , isRequired : false
      , margin     : 'dense'
      , type       : 'select'
      , value      : 0
      , options    : [
        { label: _(msg`None`), value: 0 }
        , ...users?.map(user => ({ label: user.username, value: user.id }))
      ]
    }
    , {
      label        : 'Action'
      , isRequired : false
      , margin     : 'dense'
      , type       : 'select'
      , value      : 0
      , options    : [
        { label: _(msg`None`), value: 0 }
        , { label: _(msg`Added crash`), value: 'Added crash' }
        , { label: _(msg`Validated crash`), value: 'Validated crash' }
        , { label: _(msg`Registered new user`), value: 'Registered new user' }
        , { label: _(msg`Modified user`), value: 'Modified user' }
      ]
    }
    , {
      label        : 'Data'
      , isRequired : false
      , margin     : 'dense'
      , type       : 'select'
      , value      : 0
      , options    : [
        { label: _(msg`None`), value: 0 }
        , { label: _(msg`Police`), value: 1 }
        , { label: _(msg`Healthcare`), value: 2 }
        , { label: _(msg`Insurance`), value: 3 }
      ]
    }
  ]

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const getLogs = useCallback((fetchParams = []) => {
    const url = new URL(logsBaseUrl)
    url.searchParams.set('limit', 500)

    fetchParams.forEach(([k, v]) => {
      url.searchParams.append(k, v)
    })

    if (!logs.length || fetchParams.length) {
      sendRequest({
        token
        , url
        , manageResData: res => {
          console.log('LOGS', res)
          const logs = res.logs.map(log => ({
            ...log
            , isUnread: dayjs(log.ts).isAfter(dayjs(lastLoginDate, 'YYYY-MM-DDTHH:mm:ssZZ'))
          }))
          setLogs(logs)
        }
      })
    }
  }, [token, sendRequest])

  const getUsers = useCallback(() => {
    sendRequest({
      token
      , url           : getUsersUrl
      , manageResData : res => {
        setUsers(res.users)
      }
    })
  }, [token, sendRequest])

  useEffect(() => {
    if (!token) return logout()
    if (isMobile) return navigate('/home', { state })
    getLogs()
    getUsers()
  }, [token, logout, isMobile, navigate, state, getLogs, getUsers])

  const handleFilterObjSubmit = params => {
    let fetchParams = []

    Object.entries(params)
      .filter(([_, v]) => v ?? v)
      .filter(([_, v]) => v !== 'None')
      .forEach(([k, v]) => {
        switch (k) {
          case 'User':
            fetchParams = [...fetchParams, ['user_id', v]]
            break
          case 'Date - From':
            fetchParams = [...fetchParams, ['after', v]]
            break
          case 'Date - To':
            fetchParams = [...fetchParams, ['before', v]]
            break
          case 'Action':
            fetchParams = [...fetchParams, ['message', v]]
            break
          case 'Data':
            fetchParams = [...fetchParams, ['organization', v]]
            break
          default:
        }
      })

    getLogs(fetchParams)
  }

  return (
    <StyledDrawer
      variant="persistent"
      anchor='right'
      open={open}
    >
      {isLoading
        ? <TableSkel length={9} />
        : <>
        <Typography sx={{ mt: 1, mb: 1 }} width='100%' variant='h4' fontWeight={700} textAlign='center'>
          <Trans>History</Trans>
        </Typography>
        <Box>
          <Card sx={{ m: 1 }}>
            <CardHeader
              action={
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label='show more'
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              }
              title='Filter'
            />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <FormProvider {...methods}>
                <CardContent>
                  <Box
                    ref={submitRef}
                    component='form'
                    onSubmit={methods.handleSubmit(handleFilterObjSubmit)}
                    display='flex'
                    flexDirection='column'
                    alignItems={'center'}
                    pl={1}
                    pr={1}
                    noValidate
                  >
                    {historyFilterElements
                      .map(elem => <FilterFormElement
                        key={elem.label}
                        elem={elem}
                        isMobile={isMobile}
                      />)}
                  </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                      variant='contained'
                      size='small'
                      onClick={() => {
                        methods.reset()
                        getLogs()
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      variant='contained'
                      size='small'
                      onClick={() => submitRef.current && submitRef.current.requestSubmit()}
                    >
                      Filter
                    </Button>
                </CardActions>
              </FormProvider>
            </Collapse>
          </Card>
        </Box>
        <Box overflow='auto'>
          <List>
              {logs.map((log, index) => (
                <ListItem
                  onClick={() => {
                    if (log.isUnread) {
                      state.newLogsCount--
                      setLogs(old => old.map(o => log.ts === o.ts ? { ...o, isUnread: false } : o))
                    }
                    if (log.crash_id) getCrash(log.crash_id, log.organization || 1, log.user_id)
                  }}
                  key={log.ts}
                  sx={{
                    flexDirection     : 'column'
                    , alignItems      : 'stretch'
                    , backgroundColor : index % 2 === 0 ? theme.palette.grey[200] : theme.palette.background.default
                    , cursor          : log.crash_id ? 'pointer' : 'default'
                  }}>
                  <StyledBadge color='info' badgeContent=' ' invisible={!log.isUnread}>
                    <OrganizationIcon organization={log.organization || -1} />
                  </StyledBadge>
                  <ListItemText sx={{ alignSelf: 'flex-start' }} secondary={`${log.username} ${dayjs(log.ts).fromNow()}`} />
                  <ListItemText primary={log.message} />
                </ListItem>
              ))}
          </List>
        </Box>
      </>}
    </StyledDrawer>
  )
}

// <Box component="main">
//   <DesktopNav position={position} subPanelElements={[]} />
//   <Box ml={isMobile ? 0 : `${drawerWidth}px`}>
//     {isHistoryOpen && <>
//       <Typography sx={{ width: '100%', mt: 2 }} variant="h5">
//         <Trans>History</Trans>
//       </Typography>
//       <Divider flexItem />
//         {/* {isLoading */}
//           {/* ? <TableSkel /> */}
//           <List>
//           {logs.map(log => (
//             <ListItem key={log.ts}>
//               <ListItemText secondary={`${log.username} ${dayjs(log.ts).fromNow()}`} />
//               <ListItemText primary={log.message} />
//             </ListItem>
//           ))}
//           </List>
//     </>}
//     <Snackbar
//       open={!!message}
//       autoHideDuration={5000}
//       anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//       TransitionComponent={SlideTransition}
//       onClose={(_, reason) => {
//         if (reason === 'clickaway') {
//           return
//         }
//         setMessage('')
//       }}>
//       <Alert variant='filled' severity="success" sx={{ width: '100%' }}>{message}</Alert>
//     </Snackbar>
//     <Snackbar
//       open={!!error}
//       autoHideDuration={5000}
//       anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//       TransitionComponent={SlideTransition}
//       onClose={(_, reason) => {
//         if (reason === 'clickaway') {
//           return
//         }
//         setMessage('')
//       }}>
//       <Alert variant='filled' severity='error' sx={{ width: '100%' }}>{error}</Alert>
//     </Snackbar>
//   <MapLibre
//     isMobile={isMobile}
//     isFormOpen={isHistoryOpen}
//     mapRef={mapRef}
//   />
//   </Box>
//   <FullPageProgress isOpen={isLoading} isMobile={isMobile} isFormOpen={isHistoryOpen} />
// </Box>

// <Box
//   ml={`${drawerWidth}px`}
//   mt={4}
//   pl={2}
//   pr={2}
//   display='flex'
//   flexDirection='column'
//   alignItems='center'
//   gap={3}
//   sx={{
//     '& .overload-header': {
//       backgroundColor   : theme.palette.primary.main
//       , color           : theme.palette.primary.contrastText
//     }
//   }}
// >
//   {message.user && <Alert sx={{ mt: 2 }} severity='success'>
//     {message.user}
//   </Alert>}
//   {error && <Alert sx={{ mt: 2 }} severity="error">
//     <Trans>There was an error: {error}.</Trans>
//   </Alert>}
// </Box>

import {
  Box,
  Stack,
  Paper,
  Typography,
  Divider,
  Chip,
  Grid,
  Button
} from '@mui/material'
import { SmallTable } from '../components/SmallTable'
import SettingsIcon from '@mui/icons-material/Settings'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'
import PeopleIcon from '@mui/icons-material/People'
import ArrowIcon from '@mui/icons-material/ArrowRight'
import {
  MERGE_CASES,
  useMergeContext,
  STATE_MACHINE
} from '../../../store/mergeContext'
import useHttp from '../../../hooks/useHttp'
import { useMemo, useState } from 'react'
import { CRASH_ID_KEY } from '../utils'
import { useLocation } from 'react-router-dom'

// eslint-disable-next-line no-restricted-globals
const postUrl = new URL('/api/match', location)

const TABLE_CONFIG = [
  {
    category   : 'Crash & Road'
    , icon       : SettingsIcon
    , count      : 0
    , totalCount : 0
  }
  , {
    category   : 'Units'
    , icon       : DirectionsCarFilledIcon
    , count      : 0
    , totalCount : 0
  }
  , {
    category   : 'People'
    , icon       : PeopleIcon
    , count      : 0
    , totalCount : 0
  }
]

export const MergePage = ({ fetchCrashes }) => {
  const {
    selectedCrashDetail,
    selectedMatchDetail,
    mergeState,
    dispatchMergeState,
    setCurrentState
  } = useMergeContext()

  const myLocation = useLocation()
  const token = myLocation.state?.token || null
  const [mergeStatus] = useState(
    () =>
      selectedCrashDetail?.done &&
      selectedCrashDetail?.units?.every(uni => uni?.done) &&
      selectedCrashDetail?.people?.every(p => p?.done)
  )
  const mergeStatusLabel = useMemo(
    () => (mergeStatus ? 'complete' : 'uncomplete'),
    [mergeStatus]
  )
  console.log('crash detail::', selectedCrashDetail)
  const mergedTableConfig = TABLE_CONFIG?.map(c => {
    if (c?.category === 'Crash & Road') {
      return {
        ...c
        , count      : selectedCrashDetail?.done ? '1' : '0'
        , totalCount : '1'
        , action     : () => dispatchMergeState({ type: MERGE_CASES.MERGE_ROAD })
      }
    }
    if (c?.category === 'Units') {
      return {
        ...c
        , count      : `${selectedCrashDetail?.units?.filter(u => u?.done)?.length}`
        , totalCount : `${selectedCrashDetail?.units?.length}`
        , action     : () =>
          dispatchMergeState({
            type  : MERGE_CASES.MERGE_UNIT
            , index : selectedCrashDetail?.units?.findIndex(u => !u.done)
          })
      }
    }
    if (c?.category === 'People') {
      return {
        ...c
        , totalCount : `${selectedCrashDetail?.people?.length}`
        , count      : `${selectedCrashDetail?.people.filter(fi => fi?.done)?.length}`
        , action     : () =>
          dispatchMergeState({
            type  : MERGE_CASES.MERGE_PERSON
            , index : selectedCrashDetail?.people?.findIndex(u => !u.done)
          })
      }
    }

    return c
  })

  console.log('merge state', mergeState)

  const { sendRequest } = useHttp()

  const sendValidateRequest = async () => {
    const crashIds = [
      selectedCrashDetail?.crash?.[CRASH_ID_KEY]
      , selectedMatchDetail?.crash?.[CRASH_ID_KEY]
    ]?.filter(f => f)
    const reducedUrl = new URL(postUrl)

    try {
      const res = await sendRequest({
        url      : reducedUrl
        , token
        , httpBody : {
          crash_id : crashIds
          , crash    : {
            crash_id : selectedCrashDetail?.crash?.[CRASH_ID_KEY]
            , ...selectedCrashDetail
            , units    : selectedCrashDetail?.units?.map((u, ui) => {
              return {
                ...u
                , people: selectedCrashDetail?.people?.filter(
                  p => p?.unitIndex === ui
                )
              }
            })
            , people: selectedCrashDetail?.people?.filter(
              fi =>
                fi?.unitIndex !== 0 ||
                fi?.unitIndex === undefined ||
                fi?.unitIndex === null
            )
          }
        }
        , httpMethod: 'POST'
      })
      await fetchCrashes()
      setCurrentState(STATE_MACHINE.CRASH_TABLE)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Stack direction="column" gap={3}>
      <Box>
        <Box display="flex" justifyContent="start" alignItems="center" gap={2}>
          <Typography variant="h4">Merge status:</Typography>
          <Chip
            size="medium"
            sx={{ borderRadius: 1 }}
            variant="filled"
            color={mergeStatus ? 'success' : 'warning'}
            label={mergeStatusLabel}
          />
        </Box>
        <Divider />
      </Box>
      {mergedTableConfig?.map(config => {
        return (
          <Box>
            <SmallTable
              category={config?.category}
              count={config?.count}
              actionLabel={
                <Box display="flex" justifyContent="center" alignItems="center">
                  Start merging <ArrowIcon />{' '}
                </Box>
              }
              totalCount={config?.totalCount}
              icon={config?.icon}
              action={config?.action}
            />
          </Box>
        )
      })}
      {mergeStatus && (
        <Grid container>
          <Grid>
            <Button
              onClick={sendValidateRequest}
              variant="contained"
              color="success"
            >
              Validate Crash
            </Button>
          </Grid>
        </Grid>
      )}
    </Stack>
  )
}
